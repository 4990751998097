import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../core/loader/loader.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  url = "/api/NotificationsEmails";

  constructor(private http: HttpClient, private loaderService:LoaderService) { }

  all(params): Observable<any> {
    this.loaderService.skipRequest(this.url, params);
    return this.http.get<any>(this.url, { params: params });
  }
  allNotificationsTo(params): Observable<any> {
    return this.http.get<any>("/api/NotificationsTo", { params: params });
  }
  getNotificationTo(id): Observable<any> {
    return this.http.get<any>("/api/NotificationsTo/" + id);
  }

  saveNotificationTo(id, obj): Observable<any> {
    return this.http.put<object>("/api/NotificationsTo/" + id, obj);
  }

  saveNotificationsTo(notification_email_id, notificationTos): Observable<any> {
    return this.http.post<object>("/api/NotificationsTo/savingNotificationsTo", {notificationTos: notificationTos, notification_email_id: notification_email_id});
  }

  allNotificationsStats(params): Observable<any> {
    return this.http.get<any>("/api/NotificationsStats", { params: params });
  }

  get(id): Observable<object> {
    return this.http.get<object>(this.url + "/" + id);
  }

  sendTest(id, email): Observable<object> {
    return this.http.get<any>(this.url + "/SendTest?id=" + id + "&email=" + email);
  }

  markToSend(data): Observable<object> {
    return this.http.get<any>(this.url + "/MarkToSend?id=" + data.id + "&date=" + data.date.toISOString());
  }

  cancelMail(id): Observable<object> {
    return this.http.get<any>(this.url + "/CancelSendMail?id=" + id);
  }

  duplicateMail(id): Observable<any> {
    return this.http.get<any>(this.url + "/duplicateMail?id=" + id);
  }

  delete(id): Observable<object> {
    return this.http.delete<object>(this.url + "/" + id);
  }
}
