<p-tabView>
  <p-tabPanel header="Plantilla">
    <div>
      <form #formDetails="ngForm" novalidate>

        <p-card>
          <ng-template pTemplate="header">
            <div class="p-card-title">
              {{'component.templates.detail.title'|translate}}
            </div>
          </ng-template>
          <div class="p-grid">
            <div class="p-col-12 p-field p-mb-0">
              <label for="name">{{'template.entity_fields.name'|translate}}</label>
              <input name="name" type="text" pInputText [(ngModel)]="template.name" (ngModelChange)="onChangeName()" required />
            </div>
            <div class="p-col-6 p-field p-mb-0">
              <label for="template_type">{{'template.entity_fields.template_type_id'|translate}}</label>
              <p-dropdown name="template_type" [(ngModel)]="template.template_type_id" [options]="templateTypes"
                optionLabel="name" optionValue="id" appendTo="body" placeholder="{{'general.select'|translate}}"></p-dropdown>
            </div>
            <div class="p-col-6 p-field p-mb-0">
              <label for="entity">{{'template.entity_fields.entity_id'|translate}}</label>
              <p-dropdown [disabled]="template.id" name="entity" [(ngModel)]="template.entity_id" [options]="entities" placeholder="{{'general.select'|translate}}"
                optionLabel="name" optionValue="id" appendTo="body"></p-dropdown>
            </div>
            <div
              [ngClass]="template.id == null?'p-col-6 p-field p-mb-0':(template.beeplugin? 'p-col-12 p-field p-pb-4': 'p-col-12 p-field p-mb-0')"
              style="z-index: 1000; background-color: #f9f9f9;">
              <label for="subject">{{'template.entity_fields.subject'|translate}}</label>
              <input name="subject" type="text" pInputText [(ngModel)]="template.subject" />
            </div>
            <div class="p-col-6 p-field p-mb-0" *ngIf="template.id == null">
              <label for="beeplugin">{{'template.entity_fields.beeplugin'|translate}}</label>
              <p-checkbox binary="true" name="beeplugin" [(ngModel)]="template.beeplugin"
                [ngModelOptions]="{standalone: true}">
              </p-checkbox>
            </div>
            <div class="p-col-12 p-field" *ngIf="!template.beeplugin && template.id != null">
              <label for="html">{{'component.templates.detail.editor'|translate}}</label>
              <editor *ngIf="showTinyMCE" appendTo="body" name="html" [(ngModel)]="template.html"
                [ngModelOptions]="{standalone: true}" apiKey="dve1lwso187it38lpq8gfhgoupl6h8kxe0zwpymefrnkyhdl"
                cloudChannel="5" [init]="tinyMceConfig"
                plugins="link media image fullscreen fullpage noneditable code table quickbars" style="width:100%">
              </editor>
            </div>
            <div *ngIf="template.beeplugin && template.id != null" style=" margin-top: -79px;" class="p-col-12">
              <div id="bee-plugin-container" class="bee-plugin-container" style="border: 1px solid #515658;"></div>
            </div>
          </div>
          <ng-template pTemplate="footer">
            <div class="p-d-flex">
              <p-button label="{{'general.action.delete'|translate}}" icon="pi pi-trash" styleClass="p-button-danger"
                (click)="delete()" *ngIf="template.id!=null"></p-button>
              <div class="flex-grow-1"></div>

              <button pButton class="p-button-secondary p-button-text p-mb-1 p-mr-3" icon="pi pi-download"
                label="{{'component.templates.detail.download_html' | translate}}" (click)="getHtml()"
                *ngIf="template.id!=null&&template.beeplugin"></button>

              <p-button label="{{'general.action.preview' | translate}}" icon="pi pi-eye"
                styleClass="p-button-secondary p-button-text" (click)="previewHTML()"
                *ngIf="template.id != null"></p-button>

              <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
                styleClass="p-button-secondary p-button-text" (click)="cancel()"></p-button>

              <p-button label="{{'general.action.save' | translate}}" icon="pi pi-check" styleClass="p-ml-2"
                (click)="save()"></p-button>
              <p-button *ngIf="template.id != null"
                label="{{'component.templates.detail.save_and_duplicate'|translate}}" icon="fa fa-copy"
                styleClass="p-ml-2" (click)="duplicateTemplate()"></p-button>

            </div>
          </ng-template>
        </p-card>
      </form>
    </div>
    <!-- <app-view #view [code]="'templates.new'" [model]="template" (onEvent)="onEvent($event)" *ngIf="template.id == null"></app-view>

      <app-view #view [code]="'templates.edit'" [model]="template" (onEvent)="onEvent($event)" *ngIf="template.id != null"></app-view>
  -->




  </p-tabPanel>
  <p-tabPanel header="{{'component.templates.detail.permissions'|translate}}">
    <p-accordion>
      <p-accordionTab header="{{'component.templates.detail.zones'|translate}}" *ngIf="zonesPermission">
        <div class="p-grid">
          <div class="p-col-12 p-field">
            <p-dropdown appendTo="body" [options]="zones" [(ngModel)]="selected_zone" optionLabel="name"
              placeholder="{{'general.select' | translate}}" (onChange)="save_zone()" [disabled]="zones.length == 0">
            </p-dropdown>
          </div>
          <!--
                    <div class="p-col-2 p-field">
                        <button pButton class="p-button-primary" label="Añadir zona"></button>
                    </div>
                    -->
          <div class="p-col-12">
            <p-table #grid [value]="selected_zones">
              <ng-template pTemplate="header">
                <tr>
                  <th width="100">{{'zone.entity_fields.id'|translate}}</th>
                  <th>{{'zone.entity_fields.name'|translate}}</th>
                  <th width="100"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-zone>
                <tr>
                  <td>{{zone.id}}</td>
                  <td>{{zone.name}}</td>
                  <td class="grid-actions">
                    <a (click)="unlinkZone(zone)" class="color-danger">
                      <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                    </a>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="3">
                    {{'general.not_data' | translate }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab header="{{'role.entity.name_plural'|translate}}">
        <div class="p-grid">
          <div class="p-col-12 p-field">
            <p-dropdown appendTo="body" [options]="roles" [(ngModel)]="selected_role" optionLabel="name"
              placeholder="{{'general.select' | translate}}" (onChange)="save_role()" [disabled]="roles.length == 0">
            </p-dropdown>
          </div>
          <div class="p-col-12">
            <p-table #grid [value]="selected_roles">
              <ng-template pTemplate="header">
                <tr>
                  <th width="100">{{'role.entity_fields.id'|translate}}</th>
                  <th>{{'role.entity_fields.name'|translate}}</th>
                  <th width="100"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-role>
                <tr>
                  <td>{{role.id}}</td>
                  <td>{{role.name}}</td>
                  <td class="grid-actions">
                    <a (click)="unlinkRole(role)" class="color-danger">
                      <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                    </a>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="3">
                    {{'general.not_data' | translate }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab header="{{'user.entity.name_plural'|translate}}">
        <div class="p-grid">
          <div class="p-col-12 p-field">
            <p-dropdown appendTo="body" [options]="users" [(ngModel)]="selected_user" optionLabel="name"
              placeholder="{{'general.select' | translate}}" (onChange)="save_user()" [disabled]="users.length == 0">
            </p-dropdown>
          </div>
          <div class="p-col-12">
            <p-table #grid [value]="selected_users">
              <ng-template pTemplate="header">
                <tr>
                  <th width="100">{{'user.entity_fields.id'|translate}}</th>
                  <th>{{'user.entity_fields.name'|translate}}</th>
                  <th>{{'user.entity_fields.email'|translate}}</th>
                  <th width="100"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-user>
                <tr>
                  <td>{{user.id}}</td>
                  <td>{{user.name}}</td>
                  <td>{{user.email}}</td>
                  <td class="grid-actions">
                    <a (click)="unlinkUser(user)" class="color-danger">
                      <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
                    </a>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="4">
                    {{'general.not_data' | translate }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </p-tabPanel>
</p-tabView>
