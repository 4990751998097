import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TransactionTypesService } from 'src/app/configuration/transaction-types/transaction-types.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { LoginService } from 'src/app/login/login.service';
import { TransactionsDetailComponent } from '../transactions-detail/transactions-detail.component';
import { TransactionsService } from '../transactions.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.scss']
})
export class TransactionsListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public transactionsFilters: any = {};
  public transaction_type_id;
  public transactionType;
  public isViewInit: boolean = false;
  constructor(
    private transactionsService: TransactionsService,
    private transactionTypesService: TransactionTypesService,
    private dialogService: DialogService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private coreDialogService: CoreDialogService,
    private messageService: MessageService,
    private loginService: LoginService,
    private viewsService: ViewsService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    
    this.transactionsFilters.responsible_id = (!this.loginService.hasPermission("TRANSACTIONS_SUPERADMIN") ? [parseInt(localStorage.getItem("userId"))] : null);
    this.transaction_type_id = this.activatedRoute.snapshot.params.transaction_type_id;
    if (this.transaction_type_id) {
      this.getTransactionType();
    }

    //marcamos para que este campo no se tenga en cuenta desde la sesion
    this.transactionsFilters._ignore_session_fields = ["transaction_type_id"];
    this.transactionsFilters._excluded_fields = ["transaction_type_id"];

  }
  ngAfterViewInit() {
    Promise.resolve(null).then(() => this.isViewInit = true);
  }

  getTransactionType() {
    this.transactionTypesService.get(this.transaction_type_id).subscribe(
      data => {
        this.transactionType = data;
      }
    );
  }

  editTransaction(transaction) {
    var id = transaction?.id;
    this.router.navigate(['/transactions/', this.transaction_type_id, (id ? id : 0)]);
  }

  delete(transaction) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.transactions.delete_message", { entity: this.view.getEntityBaseName() }),
      header:  this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.transactionsService.delete(transaction.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("transactions.list.results", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => { }
    });
  }

  search() {
    this.transactionsFilters = Object.assign(this.transactionsFilters, { is_page_principal: true });
    
    this.view.fireEvent("transactions.list.results", "reload");
  }

  onEvent(event: any) {

    if (event.component == "transactions.list.filters") {
      if (event.event == "action" && event.action.name == "search") this.search();
    } else if (event.component == "transactions.list.results") {
      if (event.event == "dblclick") this.editTransaction(event.data);
      if (event.event == "action" && event.action.name == "add") this.editTransaction(null);
      if (event.event == "action" && event.action.name == "edit") this.editTransaction(event.data);
      if (event.event == "action" && event.action.name == "delete") this.delete(event.data);
      if (event.event == "action" && event.action.name == "search") {
        this.search();
      }
    }
  }
}
