<ul class="breadcrumb">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}}
      </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/configuration/attachments']"><!--{{view.getEntityBaseName(true)}}-->
      <!--Documentación general--> {{'generic_attachment.entity.name_plural' | translate}}</a>
  </li>
</ul>
<p-card>

  <ng-template pTemplate="header">
    <div class="p-card-title">
      {{'general.filter.title' | translate}}
      <div class="p-card-title-actions">
        <a label="Limpiar filtros" (click)="clearFilters()"><i class="pi pi-filter-slash"></i> {{'general.filter.clean' | translate}}</a>
        <a label="{{'general.filter.search' | translate}}" (click)="search()"> <i
            class="pi pi-search"></i>{{'general.filter.search' | translate}}</a>
      </div>
    </div>
  </ng-template>
  <div class="p-grid">

    <div class="p-col-4 p-field">
      <label for="attachment_type_id">{{'generic_attachment.entity_fields.attachment_type' | translate}}</label>
      <p-dropdown name="attachmentType" [options]="attachmentsTypes" optionValue="id" placeholder="{{'general.select' | translate}}"
        [showClear]="true" appendTo="body" optionLabel="name"
        [(ngModel)]="genericAttachmentsFilters.attachment_type_id">
      </p-dropdown>
    </div>
    <div class="p-col-4 p-field">
      <label for="name">{{'generic_attachment.entity_fields.filename' | translate}}</label>
      <input id="name" type="text" pInputText [(ngModel)]="genericAttachmentsFilters.filename" />
    </div>
    <div class="p-col-4 p-field">
      <label for="description">{{'generic_attachment.entity_fields.description' | translate}}</label>
      <input id="description" type="text" pInputText [(ngModel)]="genericAttachmentsFilters.description" />
    </div>
  </div>
</p-card>
<p-card styleClass="p-mt-4 p-card-content-fit">
  <ng-template pTemplate="header">
    <div class="p-card-title">
      {{'generic_attachment.entity.name_plural' | translate}}
      <div class="p-card-title-actions">
        <a *ngIf="createAttachmentPermission" (click)="editAttachment({ id: 0 })"><fa-icon
            [icon]="['fas', 'plus-circle']"></fa-icon> {{'component.generic_attachments.detail.new_entity' |
          translate}}</a>
      </div>
    </div>
  </ng-template>
  <p-table #grid [value]="genericAttachments" [lazy]="true" (onLazyLoad)="loadData($event)" [loading]="loading"
    [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [showCurrentPageReport]="true"
    currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRecords } }}"
    [rowsPerPageOptions]="[5,10,25,50]" paginatorDropdownAppendTo="body">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="id" width="100">{{'generic_attachment.id' | translate}}</th>
        <th pSortableColumn="attachment_type_id">{{'generic_attachment.entity_fields.attachment_type' | translate}}</th>
        <th pSortableColumn="filename">{{'generic_attachment.entity_fields.filename' | translate}}</th>
        <th pSortableColumn="size">{{'generic_attachment.entity_fields.size' | translate}}</th>
        <th pSortableColumn="created_at">{{'generic_attachment.entity_fields.created_at' | translate}}</th>
        <th pSortableColumn="created_by">{{'generic_attachment.entity_fields.created_by' | translate}}</th>
        <th width="100"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-attachment>
      <tr (dblclick)="editAttachment(attachment)">
        <td>{{attachment.id}}</td>
        <td>{{attachment.attachmentType?.name}}</td>
        <td>{{attachment.filename}}</td>
        <td>{{(attachment.size/1000).toFixed(0)}} KB</td>
        <td>{{attachment.created_at | date: 'dd/MM/yyyy HH:mm'}}</td>
        <td>{{attachment.createdBy?.name}}</td>
        <td class="grid-actions">
          <a *ngIf="editAttachmentPermission" (click)="downloadAttachment(attachment)"><fa-icon
              [icon]="['fas', 'download']"></fa-icon></a>
          <a *ngIf="editAttachmentPermission" (click)="editAttachment(attachment)"><fa-icon
              [icon]="['far', 'edit']"></fa-icon></a>
          <a *ngIf="deleteAttachmentPermission" (click)="deleteAttachment(attachment)" class="color-danger"><fa-icon
              [icon]="['far', 'trash-alt']"></fa-icon></a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-card>
