import { Component, HostListener, OnInit, Pipe, PipeTransform } from '@angular/core';
import { LoginService } from '../login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { UsersService } from 'src/app/users/users.service';
import { UpdateService } from '../../update/update.service';
import { VersionService } from 'src/app/core/version.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})

export class LoginComponent implements OnInit {
  public version: any = {};
  private auth_token: string = null;
  private returnUrl: string = "/home";
  private returnParam: any = null;
  public errorMessage: string = "";
  public loginData: any = {
    code: "",
    username: "",
    password: "",
    remember: false
  };
  public pruebaDate: any = "2023-08-31T12:35:00";

  constructor(
    private versionService: VersionService,
    private router: Router,
    private loginService: LoginService,
    private dialogService: DialogService,
    private usersService: UsersService,
    private route: ActivatedRoute,
    private updateService: UpdateService,
    private translateService: TranslateService
  ) {
    this.getVersion();
  }

  @HostListener('window:message', ['$event'])
  onMessage(event) {
    if (event.data && event.data.action == "get") {
      //cargamos datos recibidos
      if (event.data.properties.code != undefined && event.data.properties.code != "undefined") this.loginData.code = event.data.properties.code;
      if (event.data.properties.username != undefined && event.data.properties.username != "undefined") this.loginData.username = event.data.properties.username;
      if (event.data.properties.password != undefined && event.data.properties.password != "undefined") this.loginData.password = event.data.properties.password;
      if (event.data.properties.remember != undefined && event.data.properties.remember != "undefined") this.loginData.remember = event.data.properties.remember;

      //si estaba habilitado recordar datos
      if (this.loginData.remember == "true") {
        if (this.loginData.code != "" && this.loginData.username != "" && this.loginData.password != "") {
          //si tenemos guardados datos, intentamos logar
          this.login();
        }
      }
    }
  }

  ngOnInit() {
    console.log("Remove old token");
    localStorage.removeItem("token");
    let remember = localStorage.getItem("remember");
    this.loginData.code = localStorage != null && remember === "true" ? localStorage.getItem("code") : "";
    this.loginData.username = localStorage != null && remember === "true" ? localStorage.getItem("username") : "";
    this.loginData.remember = localStorage != null && remember === "true" ? true : false;

    this.route.queryParamMap.subscribe(paramMap => {
      if (paramMap.has("key")) {
        this.auth_token = paramMap.get('key');
        if (this.auth_token != null) {
          this.loginService.loginByToken(this.auth_token).subscribe(
            data => {
              if (data.token != null) {
                this.loginData.code = data.code;
                this.loginData.username = data.email;
                this.processLoginResponse(data);
              } else {
                this.errorMessage = "Token de acceso incorrecto.";
              }
            },
            error => {
              console.log(error);
              this.errorMessage = error.error.title;
            }
          );
        }
      }
    });

    //solicitamos a ventana origen datos guardados, esto es para la app que se embede en iframe pueda acceder al localstorage
    if (window.parent) {
      let message: any = {
        action: "get",
        properties: "code,username,password,remember"
      };
      window.parent.postMessage(message, '*');
    }
  }

  login() {
    this.errorMessage = "";
    if (this.loginData.username == "apple@axialcrm.com") this.loginData.code = "0001";
    if (this.loginData.username == "" || this.loginData.password == "" || this.loginData.code == "") {
      this.errorMessage = this.translateService.instant("component.login.error_message"); // "Debes indicar la cuenta, el email y la contraseña de acceso.";
      return;
    }

    this.loginService.login(this.loginData).subscribe(
      data => {
        if (data.token != null) {
          this.processLoginResponse(data);
        } else {
          this.errorMessage = this.translateService.instant("component.login.error_message_user_incorrect"); //"Datos de acceso incorrectos.";
        }
      },
      error => {
        console.log(error);
        this.errorMessage = error.error.title;
      }
    );
  }

  processLoginResponse(data: any) {
    //Limpiamos los tokens primero por si hubiera datos de una sesión anterior: 
    this.loginService.logout(true);

    //actualizamos datos de sesion de conexion actual
    this.loginService.loadedCompanyId = data.companyId;
    this.loginService.loadedUserId = data.userId;

    //Ahora rellenamos los datos de la sesión que vamos a usar:
    localStorage.setItem("token", data.token);
    localStorage.setItem("tokenExpires", data.expires);
    localStorage.setItem("code", data.companyCode);
    localStorage.setItem("username", data.email);
    localStorage.setItem("userId", data.userId);
    localStorage.setItem("name", data.name);
    localStorage.setItem("email", data.email);
    localStorage.setItem("remember", this.loginData.remember);
    localStorage.setItem("permissions", data.permissions);
    localStorage.setItem("features", data.features);
    localStorage.setItem("forgotPassword", data.forgotPassword);
    localStorage.setItem("roleId", data.roleId);
    localStorage.setItem("companyName", data.companyName);
    localStorage.setItem("companyCode", data.companyCode);
    localStorage.setItem("companyId", data.companyId);
    localStorage.setItem("isSuperAdmin", data.isSuperAdmin);
    localStorage.setItem("language", data.language);

    //los mandamos guardamos asi tambien por el mobile que embede el iframe
    if (window.parent) {
      window.parent.postMessage({ action: 'set', property: 'code', value: this.loginData.code }, '*');
      window.parent.postMessage({ action: 'set', property: 'username', value: this.loginData.username }, '*');
      window.parent.postMessage({ action: 'set', property: 'password', value: this.loginData.password }, '*');
      window.parent.postMessage({ action: 'set', property: 'remember', value: this.loginData.remember }, '*');
    }

    //Cargamos las zonas y los subordinados del usuario.
    this.usersService.getUserSubordinatesIdsAndZonesIds(data.userId).subscribe(
      dataUser => {
        let userdata = dataUser;
        localStorage.setItem("userSubordinatesIds", userdata.userSubordinatesIds);
        localStorage.setItem("userZonesIds", userdata.userZonesIds);

        this.updateService.db().subscribe(
          result => {
            if (result.success) {

              //enviamos a preload con la ruta recibida
              let url = this.returnUrl;
              //location.reload();
//              this.router.navigateByUrl("/preload?returnUrl=" + encodeURIComponent(url));
              //location.replace("/preload?returnUrl=" + encodeURIComponent(url)); //Lo hacemos así para recargar la I18
              this.translateService.reloadLang(data.language).subscribe((value:any) => {

                this.translateService.use(data.language).subscribe((value:any) => {
                  this.loginService.setLoggedState(true);
                  this.router.navigateByUrl("/preload?returnUrl=" + encodeURIComponent(url));
                });

              });
            } else {
              this.errorMessage = result.message;
            }
          },
          error => {
            console.log(error);
            this.errorMessage = error.error.title;
          }
        );
      },
      error => {
        console.log(error);
        this.errorMessage = error.error.title;
      }
    );
  }

  forget_password() {
    const ref = this.dialogService.open(ForgetPasswordComponent, {
      data: {
        id: 0,
        code: this.loginData.code
      },
      header: "Recuperar contraseña",
      width: "40%",
      /*baseZIndex: 1000*/
    });
    ref.onClose.subscribe((data: any) => {
      if (data !== "undefined") {
        //this.loadData(this.lastGridEvent);
      }
    });
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  getVersion() {
    this.versionService.get().subscribe(
      data => {
        this.version = data;
      }
    );
  }
}
