import { formatDate } from "@angular/common";
import { Component, Input, OnInit, ViewChild, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { OverlayPanel } from "primeng/overlaypanel";

@Component({
    selector: 'app-view-component-datetime',
    templateUrl: 'datetime.component.html',
    styleUrls: ['datetime.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ViewComponentDateTimeComponent),
        multi: true
    }]
})
export class ViewComponentDateTimeComponent implements OnInit, ControlValueAccessor {

  public date_range: Date[] | undefined;
  public date:Date = new Date();
  public time:Date = new Date();
  public hour:number = 0;
  public minute:number = 0;
  public date_string: string = "";
  public dates_string: string = "";
  public mask = "";
  public placeholder = "";
  public is_showed : boolean = false;

  @ViewChild('op') public op:OverlayPanel;

  @ViewChild('targetEl') public targetEl:any;

  @Input('show-time') public showTime:boolean = false;
  @Input('step-minute') public stepMinute: number = 1;
  @Input('range') public range: boolean = false;
  @Input('dateValue') public value_date_range: string = "";

    private _value: string;

    // Whatever name for this (myValue) you choose here, use it in the .html file.
    /*public get myValue(): string { return this._value }
    public set myValue(v: string) {
    if (v !== this._value) {     
        this._value = v;
        this.onChange(v);
    }
    }*/
    
    ngOnInit(): void {

        this.date.setMinutes(0);
        this.date.setSeconds(0);
        this.date.setMilliseconds(0);

        //establecemos mask dependiendo del tipo de control
        if(this.showTime){
            this.mask = "99/99/9999 99:99";
            this.placeholder = "dd/MM/yyyy HH:mm";
        }else{
            this.mask = "99/99/9999";
            this.placeholder = "dd/MM/yyyy";
      }
    }

    onChange = (_) => { };
    onTouched = () => { };

    writeValue(value: any): void {    
        //console.log("writeValue", value);
        //this.myValue = value;

      if (!this.range) {
        //comprobamos minut o que sea multiplo del stepMinute indicado
        if (value != null && value != "") {
          let tmpDate: Date;
          if (value instanceof Date) {
            tmpDate = value;
          } else {
            tmpDate = new Date(value);
          }
          if (this.showTime) {
            this.date_string = formatDate(tmpDate, 'dd/MM/yyyy HH:mm', 'es-ES');
          } else {
            this.date_string = formatDate(tmpDate, 'dd/MM/yyyy', 'es-ES');
          }
          this.date = tmpDate;
          this.time = tmpDate;
          this.hour = this.time.getHours();
          this.minute = this.time.getMinutes();
        } else {
          //nada
        }
      } else {
        //Si llega será con intervalo, supongo.
        console.log(value);
        if (value != null && value != "") {
          this.date_range = [];
          var auxDate = value.split("|");
          auxDate.forEach((item: any) => {
            this.date_range.push(item);
          });
          this.onSelectRange();
        }else{
          this.date_range = [];
          this.dates_string = "";
        }
      }
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    onTimeBlur(){
        this.time.setHours(this.hour);
        this.time.setMinutes(this.minute);
        this.onComplete(null);
    }

    hide(){
        this.op.hide();
    }

    onShow(){
        this.is_showed = true;
    }
    onHide(){
        this.is_showed = false;
    }
    
    onInputMaskFocus($event){
        this.op.show($event, this.targetEl.nativeElement);
        setTimeout(()=>{
            $event.target.focus();
        }, 500);
    }

    onSelect(component:any){
        this.prepareDateString(true);
      this.onComplete(null);
      if(component == "calendar") this.hide();
    }

    prepareDateString(checkMinutes){

        let tmpDate = this.date;
        if(this.showTime && this.time){
            tmpDate.setHours(this.time.getHours());
            tmpDate.setMinutes(this.time.getMinutes());
        }
        
        if(this.showTime){
            //si es con hora, ajustamo minutos si corresponde
            if(checkMinutes) tmpDate = this.checkMinutes(tmpDate);
            this.date_string = formatDate(tmpDate, 'dd/MM/yyyy HH:mm', 'es-ES');
        }else{
            this.date_string = formatDate(tmpDate, 'dd/MM/yyyy', 'es-ES');
        }
        //console.log("prepareDateString", this.date_string, this.date, this.time);
    }

    checkMinutes(date:Date):Date{
        //comprobamos minuto que sea multiplo del stepMinute indicado, si no, lo ajustamos
        var steps = date.getMinutes() / this.stepMinute;
        date.setMinutes(Math.floor(steps) * this.stepMinute);
        return date;
    }
    onComplete($event){
        //intenamos convertir en fecha
        let parseableString:string = "";
        if(this.showTime){
            //con hora
            let parts = this.date_string.split(' ');
            let dateParts = parts[0].split('/');
            let timeParts = parts[1].split(':');
            parseableString = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0] + "T" + timeParts[0] + ":" + timeParts[1];
        }else{
            //solo fecha
            let parts = this.date_string.split('/');
            parseableString = parts[2] + "-" + parts[1] + "-" + parts[0];
        }
        let date = Date.parse(parseableString);
        if(!isNaN(date)){
            let tmpDate = new Date(date);
            
            this.date = tmpDate;
            this.time = tmpDate;

            this.prepareDateString(false);

            //emitimos cambio de valor
            let isoString = this.date.toISOString();
            if(!this.showTime) isoString = isoString.replace("Z", "");
            this.onChange(isoString);
        }else{
            this.date_string = "";
        }

    }

    onClear(){
      this.date_string =
        this.dates_string = "";
      this.date_range = [];
      this.onChange(null)
    }

  onSelectRange() {
    this.dates_string = "";
    let dates_array = "";

    let tmpDateTrim = this.date_range.toString().split(",");

    tmpDateTrim.forEach((tmpDate: any) => {
      if (tmpDate != "") {
        if (this.showTime && this.time) {
          tmpDate.setHours(this.time.getHours());
          tmpDate.setMinutes(this.time.getMinutes());
        }

        if (this.showTime) {
          //si es con hora, ajustamo minutos si corresponde
          tmpDate = this.checkMinutes(tmpDate);
          this.date_string = formatDate(tmpDate, 'dd/MM/yyyy HH:mm', 'es-ES');
        } else {
          this.date_string = formatDate(tmpDate, 'dd/MM/yyyy', 'es-ES');
        }
        this.dates_string += (this.dates_string == "" ? this.date_string : " - " + this.date_string);

        //Los creamos kukis para enviarlos.
        let parseableString: string = "";
        //solo fecha
        let parts = this.date_string.split('/');
        parseableString = parts[2] + "-" + parts[1] + "-" + parts[0];
        let date = Date.parse(parseableString);
        if (!isNaN(date)) {
          //console.log(this.date_string, date);
          let tmpDate = new Date(date);

          this.date_range.push(tmpDate);

          this.prepareDateString(false);
          //console.log(this.date_string, this.date, this.date.toISOString());

          //emitimos cambio de valor
          //console.log(this.date, this.date.toISOString());
          let isoString = tmpDate.toISOString();
          if (!this.showTime) isoString = isoString.replace("Z", "");
          //dates_array.push(isoString);
          dates_array += (dates_array == "" ? isoString : "|" + isoString);

        } else {
          //console.log("Date parsing error", date_string, parseableString);
          this.date_string = "";
        }
      }
    });

    this.onChange(dates_array);

  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  generate(direction: any, type: any, number: any) {
    var now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    var calculate = new Date();
    calculate.setHours(0);
    calculate.setMinutes(0);
    calculate.setSeconds(0);

    if (type == "week") {

      //Obtenemos el lunes de ésta semana. Y se le suma o resta una semana
      var monday = this.getMonday(now);
      
      if (direction == "-") {
        calculate.setDate(calculate.getDate() + ((direction == "-" ? -number : +number)));
        now = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() - 1);

      } else {
        //El lunes más 7 días Y para el calculado se le suman los que dice number
        now = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + 7);
        calculate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (direction == "-" ? -number : +number));
      }

    } else if (type == "month") {

      //Obtenemos el mes de inicio.
      calculate.setMonth(calculate.getMonth() + ((direction == "-" ? -number : +number) ));
      if (direction == "-") {
        //Obtenemos el último deía del mes anterior.
        calculate.setDate(1);
        var lastDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 0).getDate();
        now = new Date(now.getFullYear(), now.getMonth()-1, lastDayOfMonth);
      } else {
        //al mes nuevo calculado, hay que sumarle
        var lastDayOfMonth = new Date(calculate.getFullYear(), calculate.getMonth()+1, 0).getDate();
        calculate.setDate(lastDayOfMonth);
        //Now es el uno del mes que viene.
        now = new Date(now.getFullYear(), now.getMonth()+1, 1);
      }

    } else if (type == "year") {

      calculate.setFullYear(calculate.getFullYear() + ((direction == "-" ? -number : +number)));
      if (direction == "-") {
        //Obtenemos el último deía del mes anterior.
        calculate.setMonth(0);
        calculate.setDate(1);
        now = new Date(calculate.getFullYear(), 11, 31);
      } else {
        //al mes nuevo calculado, hay que sumarle
        calculate.setMonth(11);
        calculate.setDate(31);
        //Now es el uno del mes que viene.
        now = new Date(calculate.getFullYear(), 0, 1);
      }

    }

    this.date_range = [];

    if (calculate > now) {
      this.date_range.push(now);
      this.date_range.push(calculate);
    } else {
      this.date_range.push(calculate);
      this.date_range.push(now);
    }
    this.onSelectRange();
  }

}  
