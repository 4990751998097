import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TimelineModule } from 'primeng/timeline';
import { CoreModule } from '../core/core.module';
import { LeadsRoutingModule } from './leads-routing.module';
import { LeadsListComponent } from './leads-list/leads-list.component';
import { LeadstaskComponent } from './leads-task/leads-task.component';
import { LeadsDetailComponent } from './leads-detail/leads-detail.component';
import { MessagesModule } from 'primeng/messages';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [LeadsListComponent, LeadstaskComponent, LeadsDetailComponent],
  imports: [
    CommonModule,
    LeadsRoutingModule,
    ButtonModule,
    TableModule,
    ConfirmDialogModule,
    DialogModule,
    FormsModule,
    CheckboxModule,
    CoreModule,
    CardModule,
    FontAwesomeModule,
    InputTextareaModule,
    DropdownModule,
    CalendarModule,
    SplitButtonModule,
    TimelineModule,
    CarouselModule,
    MessagesModule,
    TranslateModule
  ]
})
export class LeadsModule { }
