<p-card styleClass="p-card-content-fit">
  <ng-template pTemplate="header">
    <div class="p-card-title">
      {{componentService.getComponentTitle(component)}}
      <small *ngIf="showConfigOptions|| debugService.enabled">Component: {{component.code}}, Entity: {{component.entity}}</small>
      <div *ngIf="!showConfigOptions" class="p-card-title-actions">
        <ng-container *ngFor="let action of component.headerActions">
          <a *ngIf="isActionVisible(action, item)" (click)="onActionClick(action, item)" class="{{action.class}}">
            <fa-icon *ngIf="action.icon!=null" [icon]="action.icon"></fa-icon>&nbsp;{{getActionLabel(action)}}
            <br *ngIf="debugService.enabled" /><small *ngIf="debugService.enabled">{{component.code}}.{{action.name}}</small>
          </a>
        </ng-container>
      </div>
      <div *ngIf="showConfigOptions" class="p-card-title-actions">
        <ng-content select="[toolbarStart]"></ng-content>
        <ng-content select="[toolbarEnd]"></ng-content>
      </div>
    </div>
  </ng-template>
  <div [ngStyle]="{ 'height': component.height }">

    <p-accordion>
      <p-accordionTab *ngFor="let attach of attachmentsTypesWithData">

        <p-header>
          <label>{{attach.name}}</label>
        </p-header>
        <div class="p-grid" style="font-size: 13px;" *ngFor="let item of getRows(attach.id)">
          <div class="p-col-fixed">
            <img src="/assets/img/mimes/{{getImage(item.mimetype)}}" class="p-mt-1" style="max-width: 100%; max-height: 40px;" />
          </div>
          <div class="p-col">
            <div class="d-flex flex-column" (click)="onDblClick(item)">
              <div class="user_name text-truncate">
                <span class="fw-bold card-title">{{getData(item, "title")}}</span>
              </div>
              <small class="emp_post text-muted">{{getData(item, "subtitle")}}</small>
              <small class="emp_post text-muted">:{{'files.configure.update' | translate}}: {{getData(item, "updated_at")}}</small>
              <small class="emp_post text-muted">{{'files.configure.create' | translate}}: {{getData(item, "created_at")}}</small>
              <small class="emp_post text-muted">{{'files.configure.create_by' | translate}}: {{item.createdBy?.name}}</small>
              <div [innerHtml]="getData(item, 'badge')">
              </div>
            </div>
          </div>
          <div class="p-col-fixed">
            <a *ngFor="let action of component.actions" (click)="onActionClick(action, item)" class="{{action.class}} p-ml-1">
              <fa-icon [icon]="action.icon" *ngIf="action.icon!=null"></fa-icon>
            </a>
          </div>
        </div>




      </p-accordionTab>
    </p-accordion>

  </div>
</p-card>

<p-overlayPanel appendTo="body" #op>
  <ng-template pTemplate>
    <div class="p-field">
      <label>{{'files.configure.title' | translate}}</label>
      <div class="p-inputgroup">
        <input pInputText [(ngModel)]="component.title" />
      </div>
    </div>
    <div class="p-field">
      <label>{{'files.configure.visible_by' | translate}}</label>
      <div class="p-inputgroup">
        <p-dropdown name="visible_by"
                    [(ngModel)]="component.visible_by"
                    [options]="visibleByOptions"
                    optionValue="code"
                    optionLabel="label"
                    appendTo="body"></p-dropdown>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
