import { Component, OnInit, ViewChild } from '@angular/core';
import { FilterService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EntitiesFieldsService } from 'src/app/configuration/entities-fields.service';
import { FamiliesService } from 'src/app/configuration/families/families.service';
import { TaxRatesService } from 'src/app/configuration/tax-rates/tax-rates.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ProductsService } from 'src/app/products/products.service';
import { TransactionsLinesService } from '../transactions-lines.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-transaction-lines-detail',
  templateUrl: './transaction-lines-detail.component.html',
  styleUrls: ['./transaction-lines-detail.component.scss']
})
export class TransactionLinesDetailComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;

  public entityBaseName: string = "transacción";
  public transactionLine: any = {};
  public tax_rates: any;
  public params: any = {};
  public families: any[] = [];
  public familiesFiltered: any[] = [];
  public products: any[] = [];
  public productsFiltered: any[] = [];
  public productsNames: any[] = [];
  public productsNamesJson: any[] = [];
  public characteristics1: any[] = [];
  public characteristics2: any[] = [];
  public characteristics1Name: string = "";
  public characteristics2Name: string = "";
  public oldItemId: any;

  constructor(
    private familiesService: FamiliesService,
    private productsService: ProductsService,
    private transactionsLinesService: TransactionsLinesService,
    private taxRatesService: TaxRatesService,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private entitiesFieldsService: EntitiesFieldsService,
    private filterService: FilterService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.filterService.register('hasAllWords', (value, filter): boolean => {
      let words = filter.toLowerCase().split(' ');
      return words.every(q => value.toLowerCase().includes(q));
      return true;
    });
    this.entityBaseName = this.config.data.entityBaseName;
    this.loadFamilies();
    this.loadTaxRates();
    this.loadData(this.config.data.id);
    this.GetEntityFieldDescription();
  }

  GetEntityFieldDescription() {
    this.entitiesFieldsService.GetEntityFieldDescription(8, "characteristics1").subscribe(
      data => {
        this.characteristics1Name = data.description;
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    );
    this.entitiesFieldsService.GetEntityFieldDescription(8, "characteristics2").subscribe(
      data => {
        this.characteristics2Name = data.description;
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    );
  }

  loadData(id) {
    if (id) {
      this.transactionsLinesService.get(id).subscribe({
        next: (data: any) => {
          this.transactionLine = data;
          this.transactionLine.tax_percent = this.transactionLine.tax_percent + "";
          this.transactionLine.custom_cost_price = this.transactionLine.cost_price * 1;
          this.params.product_id = this.transactionLine.product_id;
          this.params.product_name = this.transactionLine.product?.name;
          this.params.family_id = this.transactionLine.product?.family_id;
          this.params.characteristics1 = this.transactionLine.product?.characteristics1;
          this.params.characteristics2 = this.transactionLine.product?.characteristics2;
          this.transactionLine.business_id = this.config.data.business_id;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    } else {
      this.transactionLine.transaction_id = this.config.data.transaction_id;
      this.transactionLine.business_id = this.config.data.business_id;
    }
  }

  loadFamilies() {
    this.familiesService.all({}).subscribe({
      next: (data: any) => {
        this.families = data.rows;
        this.loadProducts();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }
  loadProducts() {
    let params = {};
    if (this.transactionLine.business_id != null) {
      params = { business_id: this.transactionLine.business_id };
    }
    this.productsService.all(params).subscribe({
      next: (data: any) => {
        this.products = data.rows;
        this.loadFilters();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  onlyUnique(value, index, self) {
    //Realiza la función de un distinct.
    return self.indexOf(value) === index;
  }

  clearFilters() {
    this.params = {};
    this.clearTransactionline();
    this.getOptions();
  }

  clearTransactionline() {
    this.transactionLine.product_id = null;
    this.transactionLine.net_price = null;
    this.transactionLine.tax_percent = null;
    this.transactionLine.price = null;
    this.transactionLine.total_amount = null;
    this.transactionLine.qty = null;
    this.transactionLine.base_amount = null;
    this.transactionLine.description = null;
    this.transactionLine.discount = null;
  }

  onDropDownChange(field: string, event: any, value: any) {
    if (event && value == null) {
      this.params.product_id = null;
      if (field == "family_id") {
        this.params.product_name = null;
        this.params.characteristics1 = null;
        this.params.characteristics2 = null;
      } else if (field == "product_name") {
        this.params.characteristics1 = null;
        this.params.characteristics2 = null;
      }
      else if (field == "characteristics1") {
        this.params.characteristics2 = null;
      }
      this.clearTransactionline();
    }
    this.loadFilters();
  }

  loadFilters() {

    if (this.params.product_id == null) delete this.params.product_id;
    if (this.params.family_id == null) delete this.params.family_id;
    if (this.params.characteristics1 == null || this.params.characteristics1 == "null") delete this.params.characteristics1;
    if (this.params.characteristics2 == null || this.params.characteristics2 == "null") delete this.params.characteristics2;
    if (this.params.product_name == null || this.params.product_name == "null") delete this.params.product_name;

    this.getOptions();
  }

  getOptions() {
    this.productsService.getOptions(this.params).subscribe({
      next: (data: any) => {
        var families_ids = data.map(m => m.family_id).filter(this.onlyUnique).filter(m => m != null);
        this.familiesFiltered = this.families.filter(f => families_ids.includes(f.id));

        var products_ids = data.map(m => m.product_id).filter(this.onlyUnique).filter(m => m != null);
        this.productsFiltered = this.products.filter(p => products_ids.includes(p.id));
        this.productsNames = this.productsFiltered.map(m => m.name).filter(this.onlyUnique);
        //Convierto el array de string a array de objetos con valor strings porque el dropdown de primeng no funciona con arrays de string por algún motivo.
        this.productsNamesJson = this.productsNames.map(m => { return { name: m }; });
        this.characteristics1 = data.map(m => m.characteristics1).filter(this.onlyUnique).filter(m => m != null).map(m => { return { label: m, value: m }; });
        this.characteristics2 = data.map(m => m.characteristics2).filter(this.onlyUnique).filter(m => m != null).map(m => { return { label: m, value: m }; });
        //this.characteristics1.push({label: "Ninguna", value: null});
        //this.characteristics2.push({label: "Ninguna", value: null});

        //si solo queda una opcion en el combo la marcamos
        if (this.familiesFiltered.length == 1) this.params.family_id = this.familiesFiltered[0].id;
        if (this.productsNames.length == 1) this.params.product_name = this.productsNames[0];
        //if (this.characteristics1.length == 1) this.params.characteristics1 = this.characteristics1[0].value;
        //if (this.characteristics2.length == 1) this.params.characteristics2 = this.characteristics2[0].value;

        //si solo habia un resultado
        if (data.length == 1) {

          //rellenamos params
          this.params.product_id = data[0].product_id;
          this.params.product_name = data[0].product_name;
          this.params.family_id = data[0].family_id;
          this.params.characteristics1 = data[0].characteristics1;
          this.params.characteristics2 = data[0].characteristics2;

          if (this.transactionLine.qty == null) this.transactionLine.qty = 1;
          if (this.transactionLine.product_id == 0 || this.transactionLine.product_id == null || (this.oldItemId != null && this.transactionLine.product_id != this.oldItemId)) {
            this.transactionLine.product = this.products.find(m => m.id == data[0].product_id);
            this.transactionLine.product_id = data[0].product_id;
            this.transactionLine.tax_rate_id = this.transactionLine.product.tax_rate_id;
            this.transactionLine.net_price = (this.transactionLine.product.net_price == null || this.transactionLine.product.net_price == '' || this.transactionLine.product.net_price == 'null' ? 0 : this.transactionLine.product.net_price);
            this.transactionLine.description = this.transactionLine.product.description;
          }
          this.calculateTotals();
        }
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  loadTaxRates() {
    this.taxRatesService.all({}).subscribe({
      next: (data: any) => {
        this.tax_rates = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  save(event: any, save_continue: Boolean = false) {
    if (this.transactionLine.net_price === null) {
      this.transactionLine.net_price = 0;
    }
    if (this.transactionLine.price === null) {
      this.transactionLine.price = 0;
    }
    if (this.transactionLine.cost_price === null) {
      this.transactionLine.cost_price = 0;
    }
    if (this.transactionLine.base_amount === null) {
      this.transactionLine.base_amount = 0;
    }
    if (this.transactionLine.total_amount === null) {
      this.transactionLine.total_amount = 0;
    }

    if (this.transactionLine.product_id == null) {
      this.transactionLine.cost_price = 0;
    }
    if (event.valid == false) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    if (event.valid) {
      if (typeof this.transactionLine.id === "undefined") {
        this.transactionsLinesService.add(this.transactionLine).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.transaction.detail.save_transaction_line_correct") });
            if (!save_continue) this.dynamicDialogRef.close();
            this.transactionLine = { transaction_id: this.config.data.transaction_id };
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        })
      } else {
      this.transactionsLinesService.save(this.transactionLine.id, this.transactionLine).subscribe({
        next: (data: any) => {
            this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.transaction.detail.add_transaction_line_correct") });
            if (!save_continue) this.dynamicDialogRef.close();
            this.transactionLine = { transaction_id: this.transactionLine.transaction_id };
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        })
      }
    } else {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
    }
  }
  cancel() {
    this.dynamicDialogRef.close();
  }

  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.transaction.detail.delete_transaction_line"),
      header:  this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.transactionsLinesService.delete(this.transactionLine.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    })
  }

  calculateTotals() {
    if (this.transactionLine.tax_rate_id > 0) {
      this.transactionLine.tax_percent = "" + this.tax_rates.find(m => m.id == this.transactionLine.tax_rate_id).percent;
    } else {
      this.transactionLine.tax_percent = "0";
    }
    if (Number.isNaN(this.transactionLine.net_price)) {
      this.transactionLine.net_price = 0;
    }
    if (this.transactionLine.net_price == null || this.transactionLine.net_price == 'null' ||
      this.transactionLine.tax_percent === null || this.transactionLine.qty == null ||
      this.transactionLine.net_price == "" || this.transactionLine.tax_percent == "" || this.transactionLine.qty == "") {
      this.transactionLine.price = null;
      this.transactionLine.base_amount = null;
      this.transactionLine.total_amount = null;
      this.transactionLine.cost_price = null;
    } else {
      var net_price = parseFloat(this.transactionLine.net_price);
      var cost_price = (this.transactionLine.custom_cost_price != null ? parseFloat(this.transactionLine.custom_cost_price) : 0);
      this.transactionLine.base_amount = net_price * this.transactionLine.qty;
      this.transactionLine.cost_price = cost_price * this.transactionLine.qty;
      this.transactionLine.price = net_price + (net_price * this.transactionLine.tax_percent / 100);
      if (this.transactionLine.discount != null) {
        this.transactionLine.base_amount = this.transactionLine.base_amount - (this.transactionLine.base_amount * this.transactionLine.discount / 100);
      }
      this.transactionLine.total_amount = this.transactionLine.base_amount + (this.transactionLine.base_amount * this.transactionLine.tax_percent / 100);
    }
  }

  onEvent(event) {
    if (event.component == "transactionLine.edit.general") {
      if (event.event == "onChange") {
        if (event.entityField.model_property == "product_id") {
          if (event.selectedItem != null && this.oldItemId != null) {
            this.transactionLine.custom_cost_price = 0;

            //Correo 13 jun 2023, 18:25 pregunt
            if (this.transactionLine.id == null || this.transactionLine.product_id == 0 || (this.oldItemId != null && this.transactionLine.product_id != this.oldItemId)) {
              this.transactionLine.description = event.selectedItem.description; // (this.transactionLine.description == "" || this.transactionLine.description == null ? event.selectedItem.description : this.transactionLine.description);
              this.transactionLine.net_price = event.selectedItem.net_price;

            }


            //this.transactionLine.family.id = event.selectedItem.family_id;
            //this.transactionLine.product.family_id = event.selectedItem.family_id;

            if (event.selectedItem.tax_rate_id > 0 && this.transactionLine.tax_rate_id == null) {
              this.transactionLine.tax_percent = "" + this.tax_rates.find(m => m.id == event.selectedItem.tax_rate_id).percent;
              this.transactionLine.tax_rate_id = event.selectedItem.tax_rate_id;

            } else {
              this.transactionLine.tax_percent = "0";
            }
            //this.transactionLine.tax_percent = "" + event.selectedItem.tax_percent;
            this.transactionLine.custom_cost_price = event.selectedItem.cost_price * 1;
          }
          this.oldItemId = event.selectedItem.id;
        } else if (event.entityField.model_property == "net_price") {
          this.transactionLine.net_price = parseFloat(event.value);
          this.calculateTotals();
        } else if (event.entityField.model_property == "qty") {
          this.transactionLine.qty = parseInt(event.value);
          this.calculateTotals();
        } else if (event.entityField.model_property == "tax_percent") {
          this.calculateTotals();
        } else if (event.entityField.model_property == "discount") {
          this.transactionLine.discount = event.value;
          this.calculateTotals();
        } else {
        }
      }
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "save") this.save(event, false);
      if (event.event == "action" && event.action.name == "save_continue") this.save(event, true);
      if (event.component == "view" && event.event == "entity-loaded") {
        this.config.header = typeof (this.transactionLine.id) !== "undefined" ?
          this.translateService.instant("component.transaction.detail.detail_entity_line", { entity_name: this.view.getEntityBaseName() })
          :
          this.translateService.instant("component.transaction.detail.new_entity_line", { entity_name: this.view.getEntityBaseName() });
      }
      if (event.event == "product_selected") {
        this.transactionLine = event.entity;
        this.calculateTotals();
      }
    }
  }
}
