import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { MailMarketingNotificationToStatsComponent } from 'src/app/mail-marketing/mail-marketing-notification-to-stats/mail-marketing-notification-to-stats.component';
import { ContactsService } from 'src/app/organizations/organizations-detail/organizations-contacts-detail/contacts.service';
import { OrganizationsService } from 'src/app/organizations/organizations.service';
import { Location } from '@angular/common';
import { MailingDetailComponent } from 'src/app/mailing/mailing-detail/mailing-detail.component';
import { LoginService } from '../../login/login.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-leads-detail',
  templateUrl: './leads-detail.component.html',
  styleUrls: ['./leads-detail.component.scss']
})
export class LeadsDetailComponent {
  public contact: any = {};
  public organization: any = {};
  @ViewChild("view") public view: ViewComponent;
  constructor(
    private route: ActivatedRoute,
    private organizationsService: OrganizationsService,
    private messageService: MessageService,
    private contactService: ContactsService,
    //private config: DynamicDialogConfig,
    //private dynamicDialogRef: DynamicDialogRef,
    private coreDialogService: CoreDialogService,
    private dialogService: DialogService,
    private router: Router,
    private viewsService: ViewsService,
    private _location: Location,
    private loginService: LoginService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.loadData(params["id"]);
    });
  }

  loadData(id: number) {
    if (id != 0) {
      this.contactService.get(id).subscribe(
        data => {
          this.contact = data;
          this.refreshAll();
        },
        error => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      );
    } else {
      this.route.params.subscribe(params => {
        this.contact.organization_id = parseInt(params["organizationId"]);
      });
    }
  }

  refreshAll() {
    this.view.view?.configuration?.components?.array?.forEach(component => {
      this.view.fireEvent(component.code, "reload");
    });
  }

  openNotificationStats() {
    const ref = this.dialogService.open(MailMarketingNotificationToStatsComponent, {
      data: {
        //organization_id: this.contact.organization_id,
        contact_id: this.contact.id,
      },
      header: this.translateService.instant("component.mailMarketing.toStats.report_clicks"),
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      //nothing
    })
  }
  delete() {
    if (this.loginService.hasPermission("LEADS_DELETE")) {
      this.coreDialogService.confirm({
        message: this.translateService.instant("component.leads.delete_message", { name: this.contact.name }),
        header: this.translateService.instant("general.confirmation_delete_title"),
        icon: "pi pi-info-circle",
        accept: () => {
          this.contactService.delete(this.contact.id).subscribe(
            data => {
              this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
              //this.dynamicDialogRef.close();
              this._location.back();
            },
            error => {
              this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
            }
          )
        },
        reject: () => {

        }
      });
    }
  }

  cancel() {
    this._location.back();
    //this.dynamicDialogRef.close();
  }

  newMail(emailAcc) {
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        to: emailAcc,
        entity_id: 2,
        entity_pk_id: this.contact.id,
        organization_id: this.contact.organization_id,
        contact_id: this.contact.id,
        //template_type_id: 1,
        template_type_code: "MAIL",
        searchRelated: true
      },
      header: this.translateService.instant("general.new_mail_title"),
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      if (data) {
        //this.dynamicDialogRef.close(data);
        location.reload();
      }
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
    });
  }
  openEmails() {
    if (this.contact.id != null) this.router.navigate(["/emails", (this.organization.id ? this.organization.id : 0), this.contact.id]); //this.cancel();
  }
  isDuplied(contact, event) {
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    this.contactService.isDuplied(contact).subscribe(
      data => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.leads.detail.leads_duplicates"),
            header: this.translateService.instant("general.error"),
            icon: 'far, exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save(event);
        }
      }
    );
  }
  save(event: any) {
    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    if (this.contact.email != null) this.contact.email = this.contact.email.trim();
    if (this.contact.email != null && this.contact.email.length > 0 && !this.validateEmail(this.contact.email)) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant('component.leads.detail.error_valid_email') });
      return;
    }

    if (typeof this.contact.id === "undefined") {
      this.contactService.add(this.contact).subscribe(
        data => {
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant('component.leads.detail.add_lead_correct') });
          //this.dynamicDialogRef.close(data);
          this._location.back();
        },
        error => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      )
    } else {
      this.contactService.save(this.contact.id, this.contact).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant('component.leads.detail.save_lead_correct') });
          //this.dynamicDialogRef.close(data);
          this._location.back();
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  onEvent(event: any) {

    if (event.event == "refreshAll" && event.action.name == "refreshAll") this.loadData(this.contact.id);
    if (event.component == "leads.detail") {
      if (event.event == "action" && event.action.name == "openNotificationStats") this.openNotificationStats();
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "save") this.isDuplied(this.contact, event);
      if (event.event == "fieldAction" && event.action.name == "new-mail") this.newMail(event.value);
      if (event.event == "action" && event.action.name == "openNotificationsStats") this.openNotificationStats();
      if (event.event == "action" && event.action.name == "openEmails") this.openEmails();
    }
    if (event.component == "view" && event.event == "entity-loaded") {
      //this.config.header = typeof (this.contact.id) !== "undefined" ? "Detalles del " + this.view.getEntityBaseName() : "Nuevo " + this.view.getEntityBaseName();
    }
  }
}
