<div *ngIf="company.is_template" class="template-alert">
  {{'general.toolbar.connected_template' | translate}}
</div>
<div class="layout-topbar-backdrop">
  <div class="layout-topbar">
    <p-overlayPanel #op appendTo="body">
      <ng-template pTemplate>

        <div style="min-width: 300px;" *ngIf="personalize_interface || views_superadmin">
          <label><small>{{'view.configure.which_view_to_show' | translate}}</small></label><br />
          <p-dropdown [options]="dropdownViews" [(ngModel)]="selectedView" optionLabel="name" optionValue="id"
                      [filter]="true" filterBy="name" class="p-field" *ngIf="personalized_views_read" appendTo="body"
                      placeholder="{{'view.configure.select_view' | translate}}" (onChange)="changeCurrentView()">
            <ng-template pTemplate="selectedItem">
              <div class="view-item">
                <div><i class="fa fa-thumbtack p-mr-1"></i> {{view.name}} </div>
              </div>
            </ng-template>
            <ng-template let-view pTemplate="item">
              <div style="display:flex;align-items:center;">
                <div style="margin-right: 5px;">
                  <i class="fa fa-thumbtack p-mr-1" *ngIf="view.id == selectedView"></i>
                </div>
                <div>
                  <div *ngIf="view.id != -1">
                    {{view.name}} <span style="font-size: 11px; color: #7b918d" *ngIf="view.metadata?.default == true">({{'view.configure.default' | translate}})</span>
                  </div>
                  <div *ngIf="view.id == -1 && personalized_views_write" class="p-text-center">
                    <p-button label="{{'general.create_new_view' | translate}}" icon="pi pi-plus" styleClass="p-ml-2 p-block"
                              (click)="newView(false); event.preventDefault()"></p-button>
                  </div>
                  <div *ngIf="view.id > 0" style="color: #a1b6b2">
                    <small>
                      <label *ngIf="view.user?.name">{{'view.configure.created_by' | translate}} {{view.user.name}}</label>
                      <label *ngIf="!view.user?.name">{{'view.configure.system_view' | translate}}</label>
                      <div *ngIf="view.updatedBy?.name">
                        {{view.updatedBy?.name ? 'Últ. Modificación: '+view.updatedBy.name:""}}
                        {{view.updated_at | date: '(dd/MM/yyyy HH:mm)':'GMT'}} {{view.updated_at ? '' : ''}}
                      </div>
                    </small>
                  </div>
                </div>
              </div>

            </ng-template>
          </p-dropdown>
        </div>
        <br />

        <div *ngIf="(view.id && view.user_id == created_by && personalize_interface) || (view.user_id && views_superadmin) || (views_superadmin && company.is_template)">
          <label><small>{{'view.configure.configure_existed' | translate}}</small></label>
          <br />
          <a class="p-button p-mt-2" *ngIf="personalize_interface" (click)="editViewClick(false)">{{'view.configure.modify_view' | translate}}</a>
        </div>
        <div *ngIf="!((view.id && view.user_id == created_by && personalize_interface) || (view.user_id && views_superadmin) || (views_superadmin && company.is_template))">
          <label><small><i class="pi pi-exclamation-triangle"></i>{{'view.configure.cant_modify_view' | translate}}.</small></label>
        </div>
        <br />
        <label *ngIf="personalize_interface || views_superadmin"><small>{{'view.configure.create_new_view' | translate}}</small></label>
        <br />
        <a class="p-button p-mt-2" *ngIf="personalize_interface || views_superadmin" (click)="newView()">
          {{'general.filter.create_new_view' | translate}}
        </a>

      </ng-template>
    </p-overlayPanel>

    <div class="p-d-flex p-jc-betwen groupedToolbar" style="justify-content: end">

      <div class="p-d-flex p-ai-center " style="flex-grow: 1">
        <div class="layot-topbar-menu-button">
          <a (click)="toogleMenu()" style="margin-right: 10px;">
            <fa-icon [icon]="['fas', 'bars']"></fa-icon>
          </a>
        </div>
        <div class="p-d-flex p-ai-center searchbox">
          <label for="data_type">
            <i class="pi pi-search"></i>
          </label>
        

          <p-autoComplete [showEmptyMessage]="true" emptyMessage="Sin datos" [(ngModel)]="searchText" placeholder="{{'general.toolbar.filter' | translate}}"
                          [suggestions]="searchResults" class="topAutoComplete" (completeMethod)="filterSearch($event)" field="text"
                          (onSelect)="viewElementDetail($event)" minLength="3">
            <ng-template let-result pTemplate="item">
              <div class="result-item" style="display:flex;align-items:center">
                <div style="margin-right: 8px;"><i class="{{result.icon}}"></i></div>
                <div>
                  <div style="font-size: 15px;" [highlight]="searchText" [inputText]="result.text"
                       customClasses="bg-transparent font-semibold color-green-axial">{{result.text}}</div>
                  <div style="color:#909090;font-size: 11px" *ngIf="result.moreInfo" [highlight]="searchText"
                       [inputText]="result.moreInfo" customClasses="bg-transparent font-semibold color-green-axial">
                    {{result.moreInfo}}
                  </div>
                </div>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>


      <div class="p-d-flex p-ai-center quick-actions">
        <div class="ablockHeaderHome vl"></div>

        <div *ngFor="let item of itemsQuickMenu" class="ablockHeaderHome" pTooltip="{{item.label}}"
             tooltipPosition="bottom">
          <a (click)="call(item)" *ngIf="item.visible && item.value == 0">
            <div class="icon"><i class="{{item.icon}}"></i></div>
          </a>
          <a (click)="call(item)" *ngIf="item.visible && item.value > 0">
            <div class="icon"><i class="{{item.icon}} p-badge-danger" pBadge value="{{item.value}}"></i></div>
          </a>
        </div>
        <!--alertas-->
        <div class="ablockHeaderHome" *ngIf="totalAlerts>0">
          <a (click)="alerts.toggle($event)" class="user-dropdown p-d-flex">
            <i pbadge="" value="3" class="p-element p-badge-danger pi pi-bell pi-fw p-overlay-badge">
              <span class="p-badge p-component">{{totalAlerts}}</span>
            </i>
          </a>
        </div>

        <div class="ablockHeaderHomeMOBILE vl"></div>
        <div class="ablockHeaderHomeMOBILE">
          <a (click)="options.toggle($event)"><i class=" pi pi-chevron-down"></i></a>
        </div>


        <div class="vl"></div>
        <a (click)="quickAddOrganization()" pTooltip="{{'general.toolbar.quick' | translate}}" tooltipPosition="bottom">
          <i pbadge="" value="2" class="pi pi-plus-circle"></i>
        </a>


        <div *ngIf="showFlag" class="vl"></div>
        <p-dropdown  *ngIf="showFlag" [options]="flags" [(ngModel)]="flag_code.code" optionLabel="name" optionValue="code" styleClass="flagsDropdown" (onChange)="changeLanguage()">
          <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="flag_code.code">
              <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + flag_code.flag_code.toLowerCase()" style="width: 18px" />
            </div>
          </ng-template>
          <ng-template let-country pTemplate="item">
            <div class="flex align-items-center gap-2">
              <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.flag_code.toLowerCase()" style="width: 18px" />
            </div>
          </ng-template>
        </p-dropdown>






        <div class="vl"></div>
        <a (click)="menu.toggle($event)" class="user-dropdown p-d-flex">
          <img src="{{imageUrl
            }}" style="height: 40px; width: 40px; margin-left: 0px; margin-right: 5px;" />

          <div class="no-mobile" style="text-align: left; ">
            <b>{{user.name}}</b><br>
            <small style="max-height: 30px; display: block; overflow: hidden;">{{user.companyName}} - Nº. {{companyCode}}</small>
          </div>
        </a>
        <div class="no-mobile vl"></div>

        <a class="no-mobile" (click)="loadViewsDropdown(); op.toggle($event)"
           *ngIf="(allowViewPersonalize && personalized_views_write && personalize_interface) || (allowViewPersonalize && views_superadmin)"
           pTooltip="{{'general.configure' | translate}}" tooltipPosition="top">
          <fa-icon [icon]="['fas','cog']" style="color: #6e6b7b"></fa-icon>
        </a>
        <div class="no-mobile vl" *ngIf="allowViewPersonalize && personalized_views_write && personalize_interface">
        </div>
      </div>
    </div>
  </div>
  <p-slideMenu #menu [model]="items" [popup]="true" appendTo="body" styleClass="slideMenuTopbar"></p-slideMenu>
  <p-slideMenu #alerts [model]="alertsNotifications" [popup]="true" appendTo="body" styleClass="slideMenuTopbar"></p-slideMenu>
  <p-slideMenu #options [model]="itemsQuickMenu" [popup]="true" appendTo="body" styleClass="slideMenuTopbar"></p-slideMenu>
</div>
