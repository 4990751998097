import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UIChart } from 'primeng/chart';
import { TransactionsService } from 'src/app/transactions/transactions.service';
import { OrganizationsService } from '../../organizations.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sales-total-pipeline',
  templateUrl: './sales-total-pipeline.component.html',
  styleUrls: ['./sales-total-pipeline.component.scss']
})
export class SalesTotalPipelineComponent implements OnInit {
  @ViewChild('chart') chart: UIChart;
  @Input() public data: any;
  @Input() public entity: any;
  public id;
  public ret: any = {};
  public height: number = 0;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private organizationService: OrganizationsService,
    private transactionService: TransactionsService,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.loadStatsSales(this.id);
    });
  }

  onEvent(event: string) {
    this.refresh();
  }

  refresh(){
    this.loadStatsSales(this.id);
    this.chart.reinit();
    this.chart.refresh();
  }

  loadStatsSales(id) {
    this.organizationService.pipelinesSales(id).subscribe(
      data => {
        this.ret = data;
        this.loadBasicData();
      },
      error => {
        //TODO: messageService aquí.
      }
    );
  }

  //Configuración de tablas horizontales para meses.
  public basicData: any;
  public horizontalOptions: any;
  public labels: any[] = [];
  public backgroundColors: any[] = [];

  loadBasicData() {
    this.labels = [];
    this.backgroundColors = [];
    let requiredHeight:number = 0;
    for (let i = 0; i < this.ret.labels.length; i++) {
      this.labels.push(this.ret.labels[i]);
      this.backgroundColors.push(this.ret.backgroundColors[i]);
      requiredHeight += 50;
    }
    this.height = requiredHeight;

    this.basicData = {
      labels: this.labels,
      datasets: [
        {
          label: this.translateService.instant("component.organizations.sales_total_pipeline.total"),
          backgroundColor: this.backgroundColors,
          data: this.ret.sumByStatus
        }
      ]
    };
    this.horizontalOptions = {
      indexAxis: 'y',
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var label = new Intl.NumberFormat('de-DE').format(tooltipItem.value);
            return label + " €";
          }
        }
      },
      plugins: {
        legend: {
          labels: {
            color: '#000057'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: '#000057'
          },
          grid: {
            color: '#ebedef'
          }
        },
        y: {
          ticks: {
            color: '#000057'
          },
          grid: {
            color: '#ebedef'
          }
        }
      }
    };
  }
}
