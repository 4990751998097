import { NgModule } from '@angular/core';
import { ReportsRoutingModule } from './reports-routing.module';
import { CommonModule } from '@angular/common';

import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { Card, CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TimelineModule } from 'primeng/timeline';
import { CoreModule } from '../core/core.module';
import { ReportsListComponent } from './reports-list/reports-list.component';
import { ReportsDetailComponent } from './reports-detail/reports-detail.component';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
//import { GMapModule } from 'primeng/gmap';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChartModule } from 'primeng/chart';
import { ReportsAskUserComponent } from './reports-detail/reports-ask-user/reports-ask-user.component';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ReportClonatorComponent } from './reports-detail/report-clonator/report-clonator.component';
import { FieldsetModule } from 'primeng/fieldset';
import { ReportMasiveUpdateComponent } from './reports-detail/report-masive-update/report-masive-update.component';
import { ReportCreateTaskComponent } from './reports-detail/report-create-task/report-create-task.component';
import { ListboxModule } from 'primeng/listbox';
import { MessageModule } from 'primeng/message';
import { ReportsRevalidationActionComponent } from './reports-detail/reports-revalidation-action/reports-revalidation-action.component';
import { ReportMailMarketingComponent } from './reports-detail/report-mail-marketing/report-mail-marketing.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FileUploadModule } from 'primeng/fileupload';
import { RadioButtonModule } from 'primeng/radiobutton';
import { GoogleMapsModule } from '@angular/google-maps';
import { IsoToDatePipe } from '../core/core.pipes';
import { MessagesModule } from 'primeng/messages';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [ReportsListComponent,
    ReportsDetailComponent,
    ReportsAskUserComponent,
    ReportClonatorComponent,
    ReportMasiveUpdateComponent,
    ReportCreateTaskComponent,
    ReportsRevalidationActionComponent,
    ReportMailMarketingComponent],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    ButtonModule,
    TableModule,
    ConfirmDialogModule,
    DialogModule,
    FormsModule,
    CheckboxModule,
    CoreModule,
    CardModule,
    FontAwesomeModule,
    InputTextareaModule,
    DropdownModule,
    CalendarModule,
    SplitButtonModule,
    TimelineModule,
    TabViewModule,
    AccordionModule,
    //GMapModule,
    MultiSelectModule,
    ChartModule,
    CascadeSelectModule,
    FieldsetModule,
    ListboxModule,
    MessageModule,
    EditorModule,
    FileUploadModule,
    RadioButtonModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    MessagesModule,
    TranslateModule
  ],
  exports: [
    ReportsListComponent,
    ReportsDetailComponent,
    ReportsAskUserComponent,
    ReportMailMarketingComponent
  ],
})
export class ReportsModule { }
