import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { AttachmentsDetailComponent } from '../attachments-detail/attachments-detail.component';
import { AttachmentsService } from '../attachments.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { ViewsService } from 'src/app/core/view/views.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-attachments-list',
  templateUrl: './attachments-list.component.html',
  styleUrls: ['./attachments-list.component.scss']
})
export class AttachmentsListComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public attachmentsFilters: any = {
    showTemplates : false,
  }
  public columns: any;

  constructor(
    private attachmentsService: AttachmentsService,
    private dialogService: DialogService,
    private coreDialogService: CoreDialogService,
    private messageService: MessageService,
    private viewsService: ViewsService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }
  edit(attachment) {
    const ref = this.dialogService.open(AttachmentsDetailComponent, {
      data: {
        entity_id: attachment ? attachment.entity_id : 12,
        entity_pk_id: 0,
        id: attachment ? attachment.id : 0
      },
      // header: attachment?"Detalles del adjunto":"Nuevo adjunto",
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.view.fireEvent("attachments.list.results", "reload");
    });
  }
  delete(attachment) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.attachments.delete_message", { description: attachment.description }), //"¿Quieres borrar el adjunto " + attachment.filename + "?",
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.attachmentsService.delete(attachment.id).subscribe(
          data => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("attachments.list.results", "reload");
          },
          error => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        )
      },
      reject: () => { }
    })
  }
  downloadFile(attachment) {
    this.attachmentsService.download(attachment.guid);
  }
  search() {
    this.view.fireEvent("attachments.list.results", "reload");
  }

  exportExcel() {
    var aux = [];
    //For que recorre cada row y column y devuelve un objeto que será exportado a excel:       
    this.attachmentsFilters.forEach(row => {
      var obj = {};
      this.columns.forEach(label => {
        obj[label.description] = row[label.model_property];
      });
      aux.push(obj);
    });
    //Promise.resolve(null).then(() => this.loading = false);
    const worksheet = XLSX.utils.json_to_sheet(aux);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, "data");
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  onEvent(event: any) {
    
    if (event.component == "attachments.list.filters") {
      if (event.event == "action" && event.action.name == "search") this.search();
      if (event.event == "action" && event.action.name == "show_templates") { this.attachmentsFilters.showTemplates = true; this.search(); }
      if (event.event == "action" && event.action.name == "hide_templates") { this.attachmentsFilters.showTemplates = false; this.search(); }
    } else if (event.component == "attachments.list.results") {
      if (event.event == "dblclick") this.edit(event.data);
      if (event.event == "action" && event.action.name == "add") this.edit(null);
      if (event.event == "action" && event.action.name == "edit") this.edit(event.data);
      if (event.event == "action" && event.action.name == "delete") this.delete(event.data);
      if (event.event == "action" && event.action.name == "download") this.downloadFile(event.data);
      if (event.event == "action" && event.action.name =="search") this.search();
    }
  }
}
