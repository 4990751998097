import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AttachmentsTypesService } from 'src/app/configuration/attachments-types/attachments-types.service';
import { TemplatesTypesService } from 'src/app/configuration/templates/templates-types.service';
import { TemplatesService } from 'src/app/configuration/templates/templates.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { CoreFormService } from 'src/app/core/forms/core-forms.service';
import { AttachmentsDataService } from '../attachments-data.service';
import { AttachmentsService } from '../attachments.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-generic-attachments-detail',
  templateUrl: './generic-attachments-detail.component.html',
  styleUrls: ['./generic-attachments-detail.component.scss']
})
export class GenericAttachmentsDetailComponent implements OnInit {
  @ViewChild("formDetails") public formDetails: NgForm;
  public attachment: any = {};
  public attachmentsTypes: any = [];
  public templates: any = [];
  public template: any = {
    name: "blank",
    html: "blank"
  };
  public selectedTemplateHtml: any;
  public html: any;
  tinyMceConfig: any;
  public showTinyMCE: boolean = false;
  public hideTabs: boolean = false;

  constructor(
    private attachmentsService: AttachmentsService,
    private attachmentDataService: AttachmentsDataService,
    private attachmentsTypesService: AttachmentsTypesService,
    private messageService: MessageService,
    public config: DynamicDialogConfig,
    private coreDialogService: CoreDialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private templatesService: TemplatesService,
    private templatesTypesService: TemplatesTypesService,
    private coreFormService: CoreFormService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.hideTabs = this.config.data.hideTabs;
    this.configureTinyMce();
    this.loadAttachmentsTypes();
    this.loadTemplates();
    this.loadData(this.config.data.id);
    this.config.header = (typeof (this.config.data.id) !== "undefined" && this.config.data.id != 0 ? this.translateService.instant("component.generic_attachments.detail.detail_entity") : this.translateService.instant("component.generic_attachments.detail.new_entity"));
  }

  configureTinyMce() {
    this.showTinyMCE = true;
    const that = this;
    this.tinyMceConfig =
    {
      height: 500,
      menubar: true,
      /*plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
        'preview paste importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help quickbars'
      ],*/
      toolbar: [
        'undo redo | bold italic underline | fontselect fontsizeselect fontsize fontfamily  formatselect |' +
        'alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist |' +
        'forecolor casechange permanentpen formatpainter removeformat | fullscreen | insertfile image media pageembed link | table quickbars | variablesButton  variablesLinesButton | previewButton'
      ],
      toolbar_mode: 'wrap',
      language: 'es',
      relative_urls: false,
      remove_script_host: false,
      document_base_url: 'https://app.axialcrm.com/',
      file_picker_types: 'image',
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = function (e) {
          var file = e.target["files"][0];
          var reader = new FileReader();
          reader.onload = (event: any) => {
            var datafile = {
              FileName: file.name,
              ContentType: file.type,
              Length: file.size,
              b64: event.target.result
            };
            that.templatesService.UploadImage(datafile).subscribe(
              (data: any) => {
                cb(data.location);
              },
              error => {
                that.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
              }
            );
          };
          reader.readAsDataURL(file);
        };

        input.click();
      }
    };
  }

  loadTemplates() {
    this.templatesService.all({ entity_id: this.config.data.entity_id }).subscribe(
      data => {
        this.templates = data.rows;
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    );
  }
  loadAttachmentsTypes() {
    this.attachmentsTypesService.all({ is_generic: true }).subscribe(
      data => {
        this.attachmentsTypes = data.rows;
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    )
  }
  loadData(id) {
    if (id) {
      this.attachmentsService.get(id).subscribe(
        data => {
          this.attachment = data;
          if (this.attachment.mimetype == "application/pdf" && this.attachment.related_attachment_id) {
            //Si abres un PDF y tiene un related_attachment_id, vuelve a abrirse con los datos de su related_attachment_id:
            this.loadData(this.attachment.related_attachment_id);
          } else if (this.attachment.mimetype == "text/html") {
            //Si es un HTML, entonces abre el modal con la edición de HTML:
            this.html = "Aquí debería cargar el HTML.";
            this.loadHtmlData();
          }
        },
        error => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      );
    } else {
      this.attachment.entity_id = this.config.data.entity_id;
      this.attachment.entity_pk_id = this.config.data.entity_pk_id;
      if (this.config.data.entity_id != null) this.searchTypeId();
    }
  }

  searchTypeId() {
    var code = "";
    //¿También deberíamos comparar el entity por code en lugar de id?
    if (this.config.data.entity_id == 8) code = "CATALOGUE_IMAGES";
    this.attachmentsTypesService.all({ code: code }).subscribe({
      next: (data: any) => {
        if (data.length > 0) {
          var attachment_type = data.rows[0];
          this.attachment.attachment_type_id = attachment_type.id;
        }
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  loadHtmlData() {
    this.attachmentDataService.getHtml(this.attachment.id).subscribe(
      data => {
        this.html = data["html"];
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    );
  }

  loadHtml() {
    if (this.selectedTemplateHtml == null) return;
    this.templatesService.toHTML(this.selectedTemplateHtml, this.config.data.entity_id, this.config.data.entity_pk_id, this.template).subscribe(
      data => {
        this.html = data.html;
        if (this.attachment.description == null || this.attachment.description.trim() == "") this.attachment.description = data.subject;
      });
  }
  onBasicUpload(event) {
    if (event.originalEvent.body.off) {
      var guidNum = 0;
      event.originalEvent.body.files.forEach(file => {
        var datafile = {
          description: file.fileName,
          filename: file.fileName,
          mimetype: file.contentType,
          size: file.length,
          guid: event.originalEvent.body.guids[guidNum],
          entity_id: this.config.data.entity_id,
          entity_pk_id: this.config.data.entity_pk_id,
          organization_id: this.config.data.organization_id,
          attachment_type_id: this.attachment.attachment_type_id
        };
        this.attachmentsService.add(datafile).subscribe(
          data => {
            this.dynamicDialogRef.close();
          },
          error => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        );
        guidNum = guidNum + 1;
      });
    } else {
      this.attachment.description = event.originalEvent.body.filename;
      this.attachment.filename = event.originalEvent.body.filename;
      this.attachment.mimetype = event.originalEvent.body.mimetype;
      //if(event.originalEvent.body.mimetype == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") this.attachment.mimetype = "application/xlsx";
      //if(event.originalEvent.body.mimetype == "application/vnd.ms-excel") this.attachment.mimetype = "application/xls";
      //if(event.originalEvent.body.mimetype == "application/vnd.openxmlformats-officedocument.presentationml.presentation") this.attachment.mimetype = "application/pptx"
      this.attachment.size = event.originalEvent.body.size;
      this.attachment.guid = event.originalEvent.body.guid;
    }
  }

  onErrorUpload(event) {
    this.messageService.add({ closable: false, severity: 'error', detail: this.translateService.instant("component.generic_attachments.detail.error_updated_file") });
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.generic_attachments.delete_message", { filename: this.attachment.filename }), //"¿Quieres borrar el adjunto " + this.attachment.filename + "?",
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.attachmentsService.delete(this.attachment.id).subscribe(
          data => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.dynamicDialogRef.close();
          },
          error => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        )
      },
      reject: () => {

      }
    })
  }
  isDuplied() {
    if (this.attachment.description == null || this.attachment.description == "") {//this.coreFormService.validate(this.formDetails)) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    this.attachmentsService.isDuplied(this.attachment).subscribe(
      data => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.generic_attachments.detail.generic_attachment_duplicate"),
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save();
        }
      }
    );
  }
  save() {
    this.attachment.organization_id = this.config.data.organization_id;
    delete this.attachment.attachmentType;
    if (typeof this.attachment.id === "undefined") {
      this.attachmentsService.addGeneric(this.attachment).subscribe(
        data => {
          this.dynamicDialogRef.close();
          this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.generic_attachments.detail.add_generic_attachment_correct") });
        },
        error => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      )
    } else {
      this.attachmentsService.save(this.attachment.id, this.attachment).subscribe(
        data => {
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.generic_attachments.detail.save_generic_attachment_correct") });
          this.dynamicDialogRef.close();
        },
        error => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      )
    }
  }
}
