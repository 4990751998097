<p-card header="" class="p-md-12" id="import">
  <div class="p-grid">
    <div class="p-col p-field">
      <p-steps [model]="steps" [(activeIndex)]="activeIndex"></p-steps>
    </div>
  </div>
  <form #formDetails="ngForm">

    <div class="p-col " *ngIf="activeIndex==0" id="importStep1">
      <div class="p-grid">
        <div class="p-col-12 p-field">
          <label for="name">{{'import.entity_fields.name' | translate}}</label>
          <input name="name" type="text" pInputText [(ngModel)]="import.name" />
        </div>
        <div class="p-col p-field">

          <label for="name">{{'component.imports.detail.file' | translate}}</label>
          <label *ngIf="import.guid">
            <a (click)="downloadImport(import)"><fa-icon [icon]="['fas', 'download']"></fa-icon></a>
            {{import.filename}}
          </label>
          <p-fileUpload chooseLabel="{{'import.entity_fields.select_file' | translate}}" [auto]="true"
                        [showCancelButton]="!import.guid" [showUploadButton]="false" name="myfile[]" url="/api/import/upload" accept=".csv"
                        (onError)="onErrorUpload($event)" (onUpload)="onBasicUpload($event)">

          </p-fileUpload>
        </div>
        <div class="p-col-12 p-field">
          <label for="entity_id">{{'component.imports.action.import' | translate}} </label>
          <p-dropdown name="entity_id"
                      (onChange)="loadEntitiesFields()"
                      [(ngModel)]="import.entity_id"
                      [options]="entities" optionValue="id" optionLabel="name" placeholder="{{'general.select' | translate}}"
                      [disabled]="import.id"
                      [showClear]="true" appendTo="body"></p-dropdown>
        </div>
        <div class="p-col-12">
          <p-accordion *ngIf="import.entity_id">
            <p-accordionTab [selected]="entity.entity.entity_id == import.entity_id" *ngFor="let entity of allowedEntities; let i = index">
              <p-header>
                <div class="">
                  <p-checkbox [disabled]="entity.entity.entity_id == import.entity_id " inputId="showReportSection"
                              [(ngModel)]="entity.checked" [binary]="true"
                              name="showReportSection{{i}}"></p-checkbox>
                  <label for="showReportSection" class="p-ml-2">{{entity.entity.entity_name}}</label>
                </div>
              </p-header>
              <div class="p-grid" *ngIf="!entity.checked"><small>{{'component.imports.detail.info_select_check' | translate}}</small></div>
              <div class="p-grid" *ngIf="entity.checked">

                <div class="p-col-12 col-md-3 p-field">
                  <label for="import_mode">{{'component.imports.detail.import_mode' | translate}}</label>
                  <p-dropdown *ngIf="(entity.entity.entity_id != import.entity_id) && (!isTransactionLines || (isTransactionLines && entity.entity.alias=='transactionLine'))" appendTo="body" [options]="import_mode" [(ngModel)]="entity.mode" optionLabel="label" optionValue="code" name="import_mode{{i}}"></p-dropdown>
                  <p-dropdown *ngIf="(entity.entity.entity_id == import.entity_id) && (!isTransactionLines || (isTransactionLines && entity.entity.alias=='transactionLine'))" appendTo="body" [options]="import_mode2" [(ngModel)]="entity.mode" optionLabel="label" optionValue="code" name="import_mode{{i}}"></p-dropdown>
                  <p-dropdown *ngIf="isTransactionLines && entity.entity.alias!='transactionLine'" appendTo="body" [options]="import_mode_only_link" [(ngModel)]="entity.mode" optionLabel="label" optionValue="code" name="import_mode{{i}}"></p-dropdown>
                </div>

                <div class="p-col-12 col-md-3 p-field">
                  <label for="identifications">{{'component.imports.detail.unique_identification' | translate}}</label>
                  <p-dropdown [options]="entity.identifications"
                              [(ngModel)]="entity.type"
                              optionLabel="label"
                              optionValue="value"
                              appendTo="body"
                              name="identifications{{i}}"
                              (onChange)="identificationsChanged()"></p-dropdown>
                </div>

                <div class="p-col-12 col-md-3 p-field-checkbox" *ngIf="entity.entity.entity_id != import.entity_id">
                  <p-checkbox [(ngModel)]="entity.ignore_null" binary="true" [ngModelOptions]="{standalone: true}" name="ignore_null{{i}}"></p-checkbox>
                  <label for="ignore_null">{{'component.imports.detail.ignore_null' | translate}}</label>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
    </div>

    <div class="p-col p-field" *ngIf="activeIndex==1">
      <ng-template pTemplate="header">
        <div class="p-card-title">
          {{'component.imports.detail.mapping' | translate}}
        </div>
      </ng-template>
      <p-accordion *ngIf="import.entity_id">
        <p-accordionTab [selected]="entityAllowed.entity.entity_id == import.entity_id" *ngFor="let entityAllowed of allowedEntities; let i = index">
          <p-header>
            {{entityAllowed.entity.entity_name}}
            <small [ngStyle]="{color:'var(--green-600)'}">{{getEntityInfo(entityAllowed.entity.entity_id)}}</small>
          </p-header>
          <p-table styleClass="p-datatable-sm" [value]="getValueFilterBy(entityAllowed.entity.entity_id)" responsiveLayout="scroll" [lazy]="true">
            <ng-template pTemplate="header">
              <tr>
                <th>{{'component.imports.detail.property' | translate}}</th>
                <th>{{'component.imports.detail.csv' | translate}}</th>
                <th>{{'component.imports.detail.type' | translate}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <td>
                  {{item.description}} <small style="color:red" *ngIf="item.is_required || item.is_required_aux">&nbsp;{{'component.imports.detail.is_required' | translate}}</small>
                </td>
                <td>
                  <p-dropdown name="headersColumn" [ngModelOptions]="{standalone: true}"
                              [options]="headersColumn" optionValue="code" optionLabel="label" placeholder="{{'component.imports.detail.not_import' | translate}}" [showClear]="true"
                              appendTo="body" [(ngModel)]="item.header" filter="true"></p-dropdown>
                </td>
                <td>
                  <input *ngIf="showHideCustomOptions(item,'input-text')"
                         [ngModelOptions]="{standalone: true}"
                         type="text"
                         pInputText
                         [(ngModel)]="item.value"
                         name="value" />

                  <p-dropdown *ngIf="showHideCustomOptions(item,'input-dropdown')"
                              [(ngModel)]="item.value" name="value"
                              [options]="getOptionsData(item)"
                              placeholder="{{'general.select' | translate}}" optionLabel="name" optionValue="id"
                              [showClear]="true" appendTo="body"
                              [ngModelOptions]="{standalone: true}"></p-dropdown>

                  <div [hidden]="!showHideCustomOptions(item,'dropdown-multiple')">
                    <p-multiSelect name="value" [(ngModel)]="item.value" [ngModelOptions]="{standalone: true}"
                                   [options]="filterValuesOptions[item.id]"
                                   optionValue="code"
                                   optionLabel="text" placeholder="{{'general.select' | translate}}" [filter]="true"
                                   [showClear]="true" appendTo="body" ngDefaultControl></p-multiSelect>
                  </div>

                  <app-view-component-datetime *ngIf="showHideCustomOptions(item,'input-datetime')&&item.data_type=='date'"
                                               name="value"
                                               [(ngModel)]="item.value" [ngModelOptions]="{standalone: true}"
                                               [show-time]="true" [step-minute]="5">
                  </app-view-component-datetime>

                  <input *ngIf="showHideCustomOptions(item,'input-number')"
                         [ngModelOptions]="{standalone: true}"
                         [(ngModel)]="item.value"
                         name="value"
                         type="number" pInputText />

                  <p-checkbox *ngIf="showHideCustomOptions(item,'input-checkbox')" binary="true"
                              name="value"
                              [(ngModel)]="item.value"
                              [ngModelOptions]="{standalone: true}">
                  </p-checkbox>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-accordionTab>
      </p-accordion>
    </div>
    <div class="p-col p-field" *ngIf="activeIndex==2">
      <p-messages [(value)]="msgs_resume" [enableService]="false" [closable]="false"></p-messages>
      <p-messages [(value)]="msgs_resume_confirm" [enableService]="false" [closable]="false"></p-messages>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <div class="p-d-flex">
      <button type="button" pButton icon="pi pi-angle-left" iconPos="left" label="{{'component.imports.detail.action.prev_step' | translate}}" style="float: left;" (click)="prevStep()" *ngIf="activeIndex!=0"></button>
      <div class="flex-grow-1"></div>
      <button type="button" pButton icon="pi pi-angle-right" iconPos="right" label="{{'component.imports.detail.action.next_step' | translate}}"
              [disabled]="!import.entity_id || !import.guid" style="float: right;" (click)="nextStep()"
              *ngIf="activeIndex!=2"></button>
      <button *ngIf="activeIndex==2" type="button" pButton icon="pi pi-save" [disabled]="disabledImportButton || (msgs_resume_confirm)" iconPos="right" style="float: right;" label="{{'component.imports.detail.action.validate' | translate}}" (click)="save(false)"></button>
    </div>
  </ng-template>
</p-card>
<br>
