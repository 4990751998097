<p-card styleClass="p-card-content-fit">
  <ng-template pTemplate="header">
    <div class="p-card-title">
      {{componentService.getComponentTitle(component)}}
      <small *ngIf="showConfigOptions|| debugService.enabled">Component: {{component.code}}, Entity: {{component.entity}}</small>
      <div *ngIf="!showConfigOptions" class="p-card-title-actions">
        <ng-container *ngFor="let action of component.headerActions">
          <a *ngIf="isActionVisible(action, item)" (click)="onActionClick(action, item, $event)" class="{{action.class}}">
            <fa-icon *ngIf="action.icon!=null" [icon]="action.icon"></fa-icon>&nbsp;{{getActionLabel(action)}}
            <br *ngIf="debugService.enabled" /><small *ngIf="debugService.enabled">{{component.code}}.{{action.name}}</small>
          </a>
        </ng-container>
      </div>
      <div *ngIf="showConfigOptions" class="p-card-title-actions">
        <ng-content select="[toolbarStart]"></ng-content>
        <a (click)="showColumnsSelector($event, 'title')" title="Titulo" class="internal-action">
          <fa-icon [icon]="['fas', 'columns']"></fa-icon>
        </a>
        <a (click)="showColumnsSelector($event, 'subtitle')" title="Subtitulo" class="internal-action">
          <fa-icon [icon]="['fas', 'columns']"></fa-icon>
        </a>
        <ng-content select="[toolbarEnd]"></ng-content>
      </div>
    </div>
  </ng-template>
  <div style="{{component.height!=null?'height: ' + component.height:''}}">
    <p-table #grid [value]="rows" [loading]="loading" [paginator]="false" [rows]="10" [totalRecords]="totalRows"
      [showCurrentPageReport]="true"
      [lazy]="true" (onLazyLoad)="preLazyLoad($event)"
      currentPageReportTemplate="{{ 'general.resumen_table_records' | translate: { first: first, last: last, totalRecords: totalRows } }}"
      [rowsPerPageOptions]="[5,10,25,50]" [scrollable]="true" scrollWidth="flex" scrollDirection="horizontal" scrollHeight="{{component.maxHeight?component.maxHeight:component.height}}">
      <ng-template pTemplate="body" let-item>

        <tr (click)="onDblClick(item)" class="{{getRowClass(item)}}">
          <td style="width: 50px;" *ngIf="component.avatar!=null">
            <div class="avatar-wrapper" *ngIf="component.avatar">
              <div class="avatar p-mr-2">
                <span class="avatar-content">{{getAvatar(item)}}</span>
              </div>
            </div>
          <td>
            <div class="d-flex flex-column">
              <div class="d-flex flex-column">
                <div class="user_name text-truncate">
                  <span class="fw-bold card-title" [innerHTML]="getData(item, 'title')"></span>
                </div>
                <small class="emp_post text-muted">
                  <label class="card-subtitle" [innerHTML]="getData(item, 'subtitle')"></label>
                </small>
                <div [innerHtml]="getData(item, 'badge')" (click)="onDblClick(item)">
                </div>
              </div>
            </div>
          </td>
          <td class="grid-actions" style="width:80px;">
            <span *ngFor="let action of component.actions">
              <a (click)="onActionClick(action, item, $event)" class="{{action.class}}" *ngIf="isActionVisible(action, item) && !locked">
                <fa-icon *ngIf="action.icon!=null" [icon]="action.icon"></fa-icon>
              </a>
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="2">
            {{'general.not_data' | translate }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-card>

<p-overlayPanel appendTo="body" #op>
  <ng-template pTemplate>
    <div class="p-field">
      <label>{{'files.configure.title' | translate}}</label>
      <div class="p-inputgroup">
        <input pInputText [(ngModel)]="component.title" />
      </div>
    </div>
    <div class="p-field">
      <label>{{'files.configure.visible_by' | translate}}</label>
      <div class="p-inputgroup">
        <p-dropdown name="visible_by" [(ngModel)]="component.visible_by" [options]="visibleByOptions" optionValue="code"
          optionLabel="label" appendTo="body"></p-dropdown>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>


<p-overlayPanel appendTo="body" #opColumns [style]="{ width: '300px' }">
  <ng-template pTemplate>
    <div style="padding-bottom: 10px;" *ngIf="fieldType=='title'">{{'card.configure.add_to_title' | translate}}</div>
    <div style="padding-bottom: 10px;" *ngIf="fieldType!='title'">{{'card.configure.add_to_subtitle' | translate}}</div>
    <p-accordion>
      <p-accordionTab *ngFor="let allowedEntity of allowedEntities">
        <ng-template pTemplate="header">{{allowedEntity.name}}</ng-template>
        <ng-template pTemplate="content">
          <p-scrollPanel [style]="{width: '100%', height: '200px'}">
            <div *ngFor="let field of getAllowedEntityFields(allowedEntity.code)">
              <div *ngIf="componentService.isVisibleFieldCustom({ entityField: field }, null)" class="p-d-flex p-m-2">
                <p-checkbox [binary]="true" [(ngModel)]="field.selected" label="{{field.description}}"
                            (onChange)="onFieldChange($event, field)"></p-checkbox>
              </div>
            </div>
          </p-scrollPanel>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </ng-template>
</p-overlayPanel>
