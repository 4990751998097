<p-card>
  <ng-template pTemplate="header">
    <div class="p-card-title">
      {{'component.calendar.filter' | translate}}
    </div>
  </ng-template>
  <div class="p-grid">
    <div class="p-col p-field">
      <label for="organizations">
        {{'organization.entity.name' | translate}}
      </label>
      <p-dropdown name="organizations" [(ngModel)]="selectedOrganization" (onChange)="onOrganizationChange()"
                  [options]="organizations" optionLabel="name" placeholder="{{'general.select' | translate}}" [showClear]="true"
                  appendTo="body" [filter]="true" (onFilter)="loadOrganizations($event)" emptyFilterMessage="No existen datos">
        <ng-template pTemplate="selectedItem">
          <div *ngIf="selectedOrganization">
            {{selectedOrganization.name}} <span *ngIf="selectedOrganization.tradename!=null">({{selectedOrganization.tradename}})</span>
          </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <div>
            {{item.name}} <span *ngIf="item.tradename!=null">({{item.tradename}})</span>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="p-col p-field">
      <label for="asunto">
        {{'task.entity_fields.subject' | translate}}
      </label>
      <input type="text" name="asunto" [(ngModel)]="subject" (ngModelChange)="loadMeeting()" placeholder="">
    </div>
    <div class="p-col p-field">
      <label for="state">
        {{'task.entity_fields.task_status_id' | translate}}
      </label>
      <p-dropdown name="states" [(ngModel)]="selectedState" (onChange)="onStateChange()" [options]="states"
                  optionLabel="name" placeholder="{{ 'general.select' | translate}}" [showClear]="true" appendTo="body" [filter]="true"
                  (onFilter)="loadStates($event)" emptyFilterMessage="No existen datos"></p-dropdown>
    </div>
  </div>
</p-card>
<div class="p-d-flex p-mt-4" id="calendarEvents" style="position: relative">
  <div class="flex-grow-1">
    <p-card>
      <ng-template pTemplate="header">
        <div class="p-card-title">
          {{'component.calendar.calendar' | translate}}
          <div class="p-card-title-actions" *ngIf="inModal">
            <a (click)="viewMeeting(null)">
              <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>
              {{'component.calendar.new_meeting' | translate}}
            </a>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="body" styleClass="">
        <div class="grid p-mt-4">
          <!--<p-fullCalendar [events]="events" [options]="options" dateFormat="mm/yy" [yearNavigator]="true"
            yearRange="2000:2030" [stepMinute]="5"></p-fullCalendar>-->
          <full-calendar [events]="events" [options]="options" dateFormat="mm/yy" [yearNavigator]="true"
                         yearRange="2000:2030" [stepMinute]="5"></full-calendar>
        </div>
      </ng-template>

    </p-card>
  </div>
  <div style="flex: 0 0 250px" class="p-ml-3" *ngIf="loginService.hasPermission('CALENDAR_OTHERS_USERS')">
    <p-card>
      <ng-template pTemplate="header">
        <div class="p-card-title">
          {{'component.calendar.calendars' | translate}}
        </div>
      </ng-template>
      <ng-template pTemplate="body" styleClass="">
        <div class="p-grid p-mt-4">
          <div *ngFor="let user of users" class="p-col-12">
            <p-checkbox binary="true" (onChange)="onChangeUser(user)" [ngModel]="user.selected"
                        [ngModelOptions]="{standalone: true}" [styleClass]="getUserCheckClass(user)" [label]="user.name"
                        [style]="{ 'background-color': user.color?user.color:'#e8e8e8' }">
            </p-checkbox>
          </div>
        </div>
      </ng-template>
    </p-card>
  </div>


  <div [ngStyle]="{'top':yPos,'left':xPos,'position': 'absolute','z-index': 10000,
  'min-width':'256px',
  'max-width':'512px',
  'word-wrap':'break-word',
  'color':'#5E5873',
  'padding':'5px 10px',
  'border-radius':'6px'
  }" ckass="tooltip_div" *ngIf="tooltip_info.show">
    <span class="tooltiptext"
          style="background-color: {{tooltip_info.backgroundColor}}; color: {{tooltip_info.textColor}}">
      <b>{{tooltip_info.showType}} - ({{tooltip_info.state}})</b><br />
      {{tooltip_info.description}} <br />
      {{tooltip_info.date_from | date:'dd/MM/yyyy HH:mm'}} ({{tooltip_info.duration}}) <br />
      <div *ngIf="tooltip_info.organization != null && tooltip_info.organization != ''">
        {{tooltip_info.organization?tooltip_info.organization:''}}
      </div>
      <div *ngIf="tooltip_info.responsible != null && tooltip_info.responsible != ''">
        {{tooltip_info.responsible}}
      </div>
      <div *ngFor="let participant of tooltip_info.participants; let i = index">
        <div *ngIf="i<3">
          {{participant.user?participant.user.name:participant.external_user}}
          {{participant.user?'('+participant.user.email+')':''}}
        </div>
      </div>
      <div *ngIf="tooltip_info.participants.length > 3">
        +{{tooltip_info.participants.length-3}} participante/s...
      </div>
      <div style="position: absolute;bottom:100%;left:6%;margin-left:-5px;border-width:5px;border-style:solid;border-color:transparent transparent {{tooltip_info.backgroundColor}} transparent;">

      </div>
    </span>
  </div>


</div>
