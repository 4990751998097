import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {
  url = "/api/transactions";

  constructor(private http: HttpClient) { }

  all(params): Observable<any> {
    return this.http.get<any>(this.url, { params: params });
  }

  get(id): Observable<any> {
    return this.http.get<any>(this.url + "/" + id);
  }

  save(id, obj): Observable<any> {
    return this.http.put<any>(this.url + "/" + id, obj);
  }

  add(obj): Observable<any> {
    return this.http.post<any>(this.url, obj);
  }

  delete(id): Observable<any> {
    return this.http.delete<any>(this.url + "/" + id);
  }
  
  unlink(id, obj): Observable<any> {
    return this.http.post<object>(this.url + "/" + id,obj);
  }

  isDuplied(obj):Observable<any>{
    return this.http.post<any>(this.url+"/isDuplied",obj);
  }

  getSaleRelated(id): Observable<object> {
    return this.http.get<any>(this.url + "/getSaleRelated/" + id);
  }
}
