<ng-content></ng-content>

<p-panel header="Component debug" [toggleable]="true" [collapsed]="true" *ngIf="debugService.enabled" styleClass="p-panel-debug p-mb-4">
  <pre>{{component | json}}</pre>
</p-panel>
<p-panel header="Model debug" [toggleable]="true" [collapsed]="true" *ngIf="debugService.enabled" styleClass="p-panel-debug p-mb-4">
  <pre>{{localModel | json}}</pre>
</p-panel>

<form [formGroup]="form" novalidate>
  <fieldset style="border: none; margin: 0px; padding: 0px;"
    [disabled]="!componentService.isEditable(component, localModel) || locked">

    <p-card>
      <ng-template pTemplate="header">
        <div class="p-card-title">
          <p-checkbox *ngIf="component.toggleable" name="isOpenned" [(ngModel)]="isOpenned" [ngModelOptions]="{standalone: true}" [binary]="true"></p-checkbox>
         {{componentService.getComponentTitle(component)}}
          <small *ngIf="showConfigOptions || debugService.enabled">Component: {{component.code}}, Entity: {{component.entity}}</small>
          <div *ngIf="!showConfigOptions" class="p-card-title-actions">
            <ng-container *ngFor="let action of component.headerActions">
              <a *ngIf="isActionVisible(action, item)" (click)="onActionClick(action, item)" class="{{action.class}}">
                <fa-icon *ngIf="action.icon!=null" [icon]="action.icon"></fa-icon> {{getActionLabel(action)}}
                <br  *ngIf="debugService.enabled"/><small *ngIf="debugService.enabled">{{component.code}}.{{action.name}}</small>
              </a>
            </ng-container>
            <a (click)="showTracking()" *ngIf="component.show_tracking && configurationPermission">
              <fa-icon [icon]="['fas', 'info-circle']"></fa-icon> 
            </a>
          </div>
          <div *ngIf="showConfigOptions" class="p-card-title-actions">
            <ng-content select="[toolbarStart]"></ng-content>
            <a (click)="showColumnsSelector($event)" class="internal-action">
              <fa-icon [icon]="['fas', 'columns']"></fa-icon>
            </a>
            <ng-content select="[toolbarEnd]"></ng-content>
          </div>
        </div>
      </ng-template>
      <div class="p-grid" [customizer]="customizableOptions" [hidden]="component.toggleable && !isOpenned">
        <div *ngFor="let field of getComponentFields()" class="{{field.class}} p-field p-mb-0 p-pb-0"
          [attr.idx]="field.idx" [hidden]="!showConfigOptions && !componentService.isVisibleField(field, localModel)">
          <label *ngIf="field.entityField?.control_type!='break'">
           {{componentService.getFieldDescription(field)}} <span *ngIf="debugService.enabled">({{field.entityField?.model_property}} (id: {{field.entityField?.id}}) = {{localModel[field.entityField?.model_property]}})</span>
          </label>
          <div class="p-inputgroup {{field.entityField?.configuration?.actions?.length>0 && isFieldActionsVisible(field)?'p-inputgroup-with-addons':''}}"
               *ngIf="field.entityField?.control_type!='break'">
            <a *ngIf="showConfigOptions" class="entity-field-configure" (click)="configField($event, field)">
              <i class="fas fa-cog"></i>
            </a>
            <label *ngIf="(field.entityField?.unmodifiable && field.entityField?.control_type!='dropdown') || (field.readonly && field.entityField?.control_type!='dropdown') || field.entityField?.control_type=='label'"
                   [innerHtml]="getModelValue(localModel, field)"
                   [ngStyle]="{ 'white-space': (field.entityField?.control_type=='input-textarea'?'break-spaces':'nowrap') }">
            </label>

            <!-- si no es de la entidad es de otra entidad, asi que no vamos a ponerlo como combo porque sino no se  muestra los de otros niveles. -->
            <label *ngIf="(component.entity != field.entityField?.entity_code  && ((field.entityField?.unmodifiable && field.entityField?.control_type=='dropdown') || (field.readonly && field.entityField?.control_type=='dropdown')))"
                   [innerHtml]="getModelValue(localModel, field)"
                   [ngStyle]="{ 'white-space': (field.entityField?.control_type=='input-textarea'?'break-spaces':'nowrap') }">
            </label>

            <!-- <span *ngIf="(field.entityField?.unmodifiable && !field.is_base_field)">{{field.entityField | json}} <br />{{localModel['organization'] | json}}</span>-->
            <!-- addons iniciales -->
            <button type="button" pButton
                    *ngFor="let action of getFieldPreActions(field)"
                    (click)="onFieldActionClick(field, action, localModel[field.entityField?.model_property])"
                    class="{{action.class}} {{action.styledClass}}" pTooltip="{{getTooltipAction(action)}}"
                    [disabled]="isFieldActionDisabled(field, action)">
              <fa-icon *ngIf="action.icon!=null" [icon]="action.icon"></fa-icon>
            </button>

            <input *ngIf="field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='input-text' && !field.entityField?.unmodifiable"
                   [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                   name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                   pInputText
                   (ngModelChange)="onChange($event, field, localModel[field.entityField?.model_property])"
                   [(ngModel)]="localModel[field.entityField?.model_property]" [ngModelOptions]="{standalone: true}"
                   class="{{getStyledClass(field)}}"
                   [required]="field.required && componentService.isVisibleField(field, localModel)" />

            <input *ngIf="!field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='input-text' && !field.entityField?.unmodifiable"
                   [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                   name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                   pInputText
                   (ngModelChange)="onChange($event, field, localModel['metadata'][field.entityField?.model_property])"
                   [(ngModel)]="localModel['metadata'][field.entityField?.model_property]"
                   [ngModelOptions]="{standalone: true}"
                   [required]="field.required && componentService.isVisibleField(field, localModel)" />

            <input *ngIf="!field.readonly && field.entityField?.control_type=='input-password' && !field.entityField?.unmodifiable"
                   [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                   name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                   pPassword type="password"
                   (ngModelChange)="onChange($event, field, localModel[field.entityField?.model_property])"
                   [(ngModel)]="localModel[field.entityField?.model_property]" [ngModelOptions]="{standalone: true}"
                   [required]="field.required && componentService.isVisibleField(field, localModel)" />

            <input *ngIf="field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='input-number' && !field.entityField?.unmodifiable"
                   [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                   name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                   type="number" (ngModelChange)="onChange($event, field, localModel[field.entityField?.model_property])"
                   [(ngModel)]="localModel[field.entityField?.model_property]" [ngModelOptions]="{standalone: true}"
                   class="p-inputtext {{getStyledClass(field)}}"
                   [required]="field.required && componentService.isVisibleField(field, localModel)" />

            <input *ngIf="!field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='input-number' && !field.entityField?.unmodifiable"
                   [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                   name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                   type="number"
                   (ngModelChange)="onChange($event, field, localModel['metadata'][field.entityField?.model_property])"
                   [(ngModel)]="localModel['metadata'][field.entityField?.model_property]"
                   [ngModelOptions]="{standalone: true}"
                   class="p-inputtext {{getStyledClass(field)}}"
                   [required]="field.required && componentService.isVisibleField(field, localModel)" />


            <app-view-component-datetime *ngIf="field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='input-date' && !field.entityField?.unmodifiable"
                                         [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                                         [required]="field.required && componentService.isVisibleField(field, localModel)"
                                         name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                                         [(ngModel)]="localModel[field.entityField?.model_property]" [ngModelOptions]="{standalone: true}">
            </app-view-component-datetime>
            <app-view-component-datetime *ngIf="!field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='input-date' && !field.entityField?.unmodifiable"
                                         [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                                         [required]="field.required && componentService.isVisibleField(field, localModel)"
                                         name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                                         [(ngModel)]="localModel['metadata'][field.entityField?.model_property]" [ngModelOptions]="{standalone: true}">
            </app-view-component-datetime>

            <!--<p-calendar *ngIf="field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='input-date' && !field.entityField?.unmodifiable"
              dateFormat="dd/mm/yy" [showIcon]="true"
              [disabled]="!componentService.isEditableField(component, field, form, localModel)"
              [required]="field.required && componentService.isVisibleField(field, localModel)"
              name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
              [ngModel]="localModel[field.entityField?.model_property] | isoToDate" [ngModelOptions]="{standalone: true}"
              (onSelect)="dateToIso($event, false, field.entityField?.model_property)"
              showClear="true" showButtonBar="true" appendTo="body" firstDayOfWeek="1" [stepMinute]="15"></p-calendar>

  <p-calendar *ngIf="!field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='input-date' && !field.entityField?.unmodifiable"
              dateFormat="dd/mm/yy" [showIcon]="true"
              [disabled]="!componentService.isEditableField(component, field, form, localModel)"
              [required]="field.required && componentService.isVisibleField(field, localModel)"
              name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
              [ngModel]="localModel['metadata'][field.entityField?.model_property] | isoToDate"
              [ngModelOptions]="{standalone: true}" showClear="true" showButtonBar="true" appendTo="body"
              (onSelect)="dateToIso($event, true, field.entityField?.model_property)"
              firstDayOfWeek="1" [stepMinute]="15"></p-calendar>-->

            <app-view-component-datetime *ngIf="field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='input-datetime' && !field.entityField?.unmodifiable"
                                         [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                                         [required]="field.required && componentService.isVisibleField(field, localModel)"
                                         name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                                         [(ngModel)]="localModel[field.entityField?.model_property]" [ngModelOptions]="{standalone: true}"
                                         [show-time]="true" [step-minute]="5">
            </app-view-component-datetime>
            <app-view-component-datetime *ngIf="!field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='input-datetime' && !field.entityField?.unmodifiable"
                                         [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                                         [required]="field.required && componentService.isVisibleField(field, localModel)"
                                         name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                                         [(ngModel)]="localModel['metadata'][field.entityField?.model_property]" [ngModelOptions]="{standalone: true}"
                                         [show-time]="true" [step-minute]="5">
            </app-view-component-datetime>

            <!--<p-calendar *ngIf="field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='input-datetime' && !field.entityField?.unmodifiable"
  dateFormat="dd/mm/yy" hourFormat="24" [showIcon]="true" [showTime]="true"
  [disabled]="!componentService.isEditableField(component, field, form, localModel)"
  name="{{field.entityField?.model_property}}"
  [required]="field.required && componentService.isVisibleField(field, localModel)"
  formControlName="{{field.entityField?.model_property}}"
  [ngModel]="localModel[field.entityField?.model_property] | isoToDate" showClear="true" showButtonBar="true"
  [ngModelOptions]="{standalone: true}" appendTo="body" firstDayOfWeek="1"
  (onSelect)="dateToIso($event, false, field.entityField?.model_property)"
  [defaultDate]="defaultDate"
  [stepMinute]="15"></p-calendar>-->
            <!--<p-calendar *ngIf="!field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='input-datetime' && !field.entityField?.unmodifiable"
  dateFormat="dd/mm/yy" hourFormat="24" [showIcon]="true" [showTime]="true"
  [disabled]="!componentService.isEditableField(component, field, form, localModel)"
  name="{{field.entityField?.model_property}}"
  [required]="field.required && componentService.isVisibleField(field, localModel)"
  formControlName="{{field.entityField?.model_property}}"
  [ngModel]="localModel['metadata'][field.entityField?.model_property] | isoToDate"
  [ngModelOptions]="{standalone: true}" showClear="true" showButtonBar="true" appendTo="body"
  (onSelect)="dateToIso($event, true, field.entityField?.model_property)"
  firstDayOfWeek="1" [stepMinute]="15"></p-calendar>-->

            <textarea *ngIf="field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='input-textarea' && !field.entityField?.unmodifiable"
                      [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                      name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                      pInputTextarea
                      (ngModelChange)="onChange($event, field, localModel[field.entityField?.model_property])"
                      [(ngModel)]="localModel[field.entityField?.model_property]"
                      [ngModelOptions]="{standalone: true}" [rows]="field.entityField?.configuration?.rows"></textarea>

            <textarea *ngIf="!field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='input-textarea' && !field.entityField?.unmodifiable"
                      [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                      name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                      pInputTextarea
                      (ngModelChange)="onChange($event, field, localModel['metadata'][field.entityField?.model_property])"
                      [(ngModel)]="localModel['metadata'][field.entityField?.model_property]"
                      [ngModelOptions]="{standalone: true}" [rows]="field.entityField?.configuration?.rows"></textarea>

            <p-colorPicker *ngIf="!field.readonly && field.entityField?.control_type=='input-colorpicker' && !field.entityField?.unmodifiable"
                           [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                           name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                           [required]="field.required && componentService.isVisibleField(field, localModel)"
                           [(ngModel)]="localModel[field.entityField?.model_property]" appendTo="body"></p-colorPicker>

            <p-autoComplete *ngIf="!field.readonly && field.entityField?.control_type=='input-autocomplete' && !field.entityField?.unmodifiable"
                            [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                            [(ngModel)]="localModel[field.entityField?.model_property]" [ngModelOptions]="{standalone: true}"
                            [suggestions]="resultsAutocomplete" [virtualScroll]="true"
                            field="{{field.entityField?.configuration.field_text}}"
                            (completeMethod)="onAutocompleteSearch($event, field.entityField)"
                            (onSelect)="onSelectAutocomplete($event, field)"
                            (onKeyUp)="onKeyUpAutocomplete($event, field, localModel[field.entityField?.model_property])"
                            appendTo="body"></p-autoComplete>
            <div *ngIf="!field.readonly && field.entityField?.control_type=='input-autocomplete' && !field.entityField?.unmodifiable">
            </div>

            <p-inputSwitch *ngIf="field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='input-switch' && !field.entityField?.unmodifiable"
                           [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                           name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                           (ngModelChange)="localModel['metadata'][field.entityField?.model_property]"
                           [(ngModel)]="localModel[field.entityField?.model_property]"
                           [required]="field.required && componentService.isVisibleField(field, localModel)"></p-inputSwitch>

            <p-fileUpload *ngIf="field.entityField?.is_base_field && !field.readonly && field.entityField.control_type=='input-fileUpload' && !field.entityField?.unmodifiable"
                          name="myFile[]" url="/api/attachments/upload"
                          [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                          (onError)="onErrorUpload($event)" (onUpload)="onBasicUpload($event)"></p-fileUpload>

            <!--TinyEditor:-->
            <editor *ngIf="!field.readonly && field.entityField?.control_type=='input-editor' && showTinyMCE && !field.entityField?.unmodifiable"
                    apiKey="dve1lwso187it38lpq8gfhgoupl6h8kxe0zwpymefrnkyhdl" cloudChannel="5"
                    [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                    name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                    [(ngModel)]="localModel[field.entityField?.model_property]" [ngModelOptions]="{standalone: true}"
                    [init]="tinyMceConfig"
                    plugins="link media image fullscreen fullpage code table quickbars" style="width: 100%"></editor>

            <!--PrimeNG Editor-->
            <p-editor *ngIf="!field.readonly && field.entityField?.control_type=='input-primengeditor' && !field.entityField?.unmodifiable"
                      [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                      name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                      [(ngModel)]="localModel[field.entityField?.model_property]" [ngModelOptions]="{standalone: true}"
                      [style]="{'height':(field.entityField?.configuration?.rows!=null?field.entityField?.configuration.rows*20 + 'px':'100px')}">
              <ng-template pTemplate="header">
                <span class="ql-formats">
                  <button type="button" class="ql-bold" aria-label="Bold"></button>
                  <button type="button" class="ql-italic" aria-label="Italic"></button>
                  <button type="button" class="ql-underline" aria-label="Underline"></button>
                </span>
                <span class="ql-formats">
                  <select name="ql-color" class="ql-color"></select>
                  <select name="ql-background" class="ql-background"></select>
                </span>
                <span class="ql-formats">
                  <button type="button" class="ql-list" aria-label="Ordered List" value="ordered"></button>
                  <button type="button" class="ql-list" aria-label="Unordered List" value="bullet"></button>
                  <select class="ql-align ql-picker ql-icon-picker"></select>
                </span>
                <span class="ql-formats">
                  <button type="button" class="ql-link" aria-label="Insert Link"></button>
                  <button type="button" class="ql-image" aria-label="Insert Image"></button>
                  <button type="button" class="ql-code-block" aria-label="Insert Code Block"></button>
                </span>
                <span class="ql-formats">
                  <button type="button" class="ql-clean" aria-label="Remove Styles"></button>
                </span>
              </ng-template>
            </p-editor>

            <p-dropdown *ngIf="(component.entity == field.entityField?.entity_code  && (field.entityField?.is_base_field && field.entityField?.control_type=='dropdown'))"
                        name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                        [(ngModel)]="localModel[field.entityField?.model_property]" (ngModelChange)="onModelChange($event, field)"
                        [ngModelOptions]="{standalone: true}" [options]="temporalCombos[field.entityField?.model_property]"
                        optionValue="{{field.entityField?.configuration?.field_value}}"
                        optionLabel="{{field.entityField?.configuration?.field_text}}" placeholder="{{'general.select' | translate}}" [showClear]="true"
                        appendTo="body" [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                        styleClass="{{(!componentService.isEditableField(component, field, form, localModel)?'p-disabled-custom':'p-ena')}}"
                        (onChange)="onChange($event, field, localModel[field.entityField?.model_property])" [filter]="true"
                        (onFilter)="onFilter($event, field)"></p-dropdown>

            <p-dropdown *ngIf="(component.entity == field.entityField?.entity_code  && (!field.entityField?.is_base_field && field.entityField?.control_type=='dropdown'))"
                        name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                        [(ngModel)]="localModel['metadata'][field.entityField?.model_property]"
                        (ngModelChange)="onModelChange($event, field)" [ngModelOptions]="{standalone: true}"
                        [options]="temporalCombos[field.entityField?.model_property]"
                        optionValue="{{field.entityField?.configuration?.field_value}}"
                        optionLabel="{{field.entityField?.configuration?.field_text}}" placeholder="{{'general.select' | translate}}" [filter]="true"
                        [showClear]="true" appendTo="body"
                        [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                        styleClass="{{(!componentService.isEditableField(component, field, form, localModel)?'p-disabled-custom':'p-ena')}}"
                        (onChange)="onChange($event, field, localModel[field.entityField?.model_property])"></p-dropdown>


            <p-multiSelect *ngIf="field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='dropdown-multiple' && !field.entityField?.unmodifiable"
                           name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                           [(ngModel)]="localModel[field.entityField?.model_property]" (ngModelChange)="onModelChange($event, field)"
                           [ngModelOptions]="{standalone: true}" [options]="temporalCombos[field.entityField?.model_property]"
                           optionValue="{{field.entityField?.configuration?.field_value}}"
                           optionLabel="{{field.entityField?.configuration?.field_text}}" placeholder="{{'general.select' | translate}}" [filter]="true"
                           [showClear]="true" appendTo="body"
                           [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                           (onChange)="onChange($event, field, localModel[field.entityField?.model_property])"></p-multiSelect>

            <p-multiSelect *ngIf="!field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='dropdown-multiple' && !field.entityField?.unmodifiable"
                           name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                           [(ngModel)]="localModel['metadata'][field.entityField?.model_property]"
                           (ngModelChange)="onModelChange($event, field)" [ngModelOptions]="{standalone: true}"
                           [options]="temporalCombos[field.entityField?.model_property]"
                           optionValue="{{field.entityField?.configuration?.field_value}}"
                           optionLabel="{{field.entityField?.configuration?.field_text}}" placeholder="{{'general.select' | translate}}" [filter]="true"
                           [showClear]="true" appendTo="body"
                           [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                           (onChange)="onChange($event, field, localModel[field.entityField?.model_property])"></p-multiSelect>

            <p-checkbox *ngIf="field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='checkbox' && !field.entityField?.unmodifiable"
                        binary="true" name="{{field.entityField?.model_property}}"
                        formControlName="{{field.entityField?.model_property}}"
                        [(ngModel)]="localModel[field.entityField?.model_property]" [ngModelOptions]="{standalone: true}">
            </p-checkbox>

            <p-checkbox *ngIf="!field.entityField?.is_base_field && !field.readonly && field.entityField?.control_type=='checkbox' && !field.entityField?.unmodifiable"
                        binary="true" [disabled]="!componentService.isEditableField(component, field, form, localModel)"
                        name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                        [(ngModel)]="localModel['metadata'][field.entityField?.model_property]"
                        [ngModelOptions]="{standalone: true}">
            </p-checkbox>

            <!-- editor codigo -->
            <ngx-monaco-editor *ngIf="!field.readonly && field.entityField?.control_type=='monaco'"
                               name="{{field.entityField?.model_property}}" formControlName="{{field.entityField?.model_property}}"
                               [options]="editorOptions" [(ngModel)]="modelMonaco[field.entityField?.model_property]"
                               [ngModelOptions]="{standalone: true}" (init)="editorInit($event)"
                               (ngModelChange)="onMonacoChange($event, field)" style="height: 500px; width: 100%;"></ngx-monaco-editor>

            <!-- addons finales -->
            <button type="button" pButton
                    *ngFor="let action of getFieldActions(field)"
                    (click)="onFieldActionClick(field, action, localModel[field.entityField?.model_property])"
                    class="{{action.class}} {{action.styledClass}}" pTooltip="{{getTooltipAction(action)}}"
                    [disabled]="isFieldActionDisabled(field, action)">
              <fa-icon *ngIf="action.icon!=null" [icon]="action.icon"></fa-icon>
            </button>
            <p-splitButton *ngFor="let action of getFieldActionsSplit(field)"
                           [hidden]="!(isActionVisible(action) && isSplitButton(action))"
                           styleClass="{{action.styledClass}}" label="{{action.label}}" icon="{{action.icon.join(' ')}}"
                           [disabled]="showConfigOptions" (onClick)="onActionClick(action, item)" appendTo="body"
                           [model]="action.options"></p-splitButton>

            <!--<span *ngFor="let action of field.entityField?.configuration?.actions">

    <button type="button" pButton *ngIf="isActionVisible(action) && !isSplitButton(action)"
      (click)="onFieldActionClick(field, action, localModel[field.entityField?.model_property])"
      class="{{action.class}}" styleClass="{{action.styledClass}}"
      [disabled]="isFieldActionDisabled(field, action)" [hidden]="!isFieldActionsVisible(field)">
      <fa-icon *ngIf="action.icon!=null" [icon]="action.icon"></fa-icon>
    </button>
    <p-splitButton *ngIf="isActionVisible(action) && isSplitButton(action)"
      styleClass="{{action.styledClass}}" label="{{action.label}}" icon="{{action.icon.join(' ')}}"
      [disabled]="showConfigOptions" (onClick)="onActionClick(action, item)" appendTo="body"
      [model]="itemsSplitButton"></p-splitButton>

  </span>-->
            <!-- addons si es email -->
            <!--<button type="button" pButton *ngIf="getFieldFormat(field)=='mail'"
      (click)="onFieldActionClick(field, { name: 'new-mail', component: component.code }, model[field.entityField?.model_property])"
      >
      <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon>
  </button>-->
          </div>
        </div>
      </div>

      <div class="p-grid p-mt-4" *ngIf="component.footerActions?.length>0">
        <div class="p-col-4 p-pb-0">
          <div class="p-grid p-pb-0 p-jc-start p-col">
            <span *ngFor="let action of getActions(component.footerActions, 'left')">
              <button type="button" pButton *ngIf="isActionVisible(action) && !isSplitButton(action)"
                      (click)="onActionClick(action, item)" class="p-mr-2 {{action.class}}"
                      styleClass="{{action.styledClass}}" [disabled]="showConfigOptions">
                <fa-icon *ngIf="action.icon!=null" [icon]="action.icon"></fa-icon>&nbsp;{{getActionLabel(action)}}
                <br  *ngIf="debugService.enabled"/><small *ngIf="debugService.enabled">{{component.code}}.{{action.name}}</small>
              </button>
              <p-splitButton *ngIf="isActionVisible(action) && isSplitButton(action)" styleClass="{{action.class}}"
                label="{{getActionLabel(action)}}" icon="{{action.icon.join(' ')}}" [disabled]="showConfigOptions"
                (onClick)="onActionClick(action, item)" appendTo="body" [model]="itemsSplitButton"></p-splitButton>
            </span>
          </div>
        </div>
        <div class="p-col-8 p-pb-0">
          <div class="p-grid p-pb-0 p-jc-end p-col">
            <span *ngFor="let action of getActions(component.footerActions, 'right')">
              <button type="button" pButton *ngIf="isActionVisible(action) && !isSplitButton(action)"
                      (click)="onActionClick(action, item)" class="p-ml-2 {{action.class}}"
                      styleClass="{{action.styledClass}}" [disabled]="showConfigOptions">
                <fa-icon *ngIf="action.icon!=null" [icon]="action.icon"></fa-icon>&nbsp;{{getActionLabel(action)}}
                <br  *ngIf="debugService.enabled"/><small *ngIf="debugService.enabled">{{component.code}}.{{action.name}}</small>
              </button>
              <p-splitButton *ngIf="isActionVisible(action) && isSplitButton(action)" styleClass="{{action.class}}"
                label="{{getActionLabel(action)}}" icon="{{action.icon.join(' ')}}" [disabled]="showConfigOptions"
                (onClick)="onActionClick(action, item)" appendTo="body" [model]="itemsSplitButton"></p-splitButton>
            </span>
          </div>
        </div>
      </div>

    </p-card>
  </fieldset>
</form>

<p-overlayPanel appendTo="body" #opField>
  <ng-template pTemplate>
    <div class="p-field">
      <label>{{'view.configure.title' | translate}}</label>
      <div class="p-inputgroup">
        <input pInputText [(ngModel)]="selectedField.label" [placeholder]="selectedField.entityField?.description" />
      </div>
    </div>
    <div class="p-field" *ngIf="!selectedField.entityField?.unmodifiable">
      <p-checkbox [(ngModel)]="selectedField.readonly" [binary]="true" label="{{'view.configure.read_only' | translate}}"></p-checkbox>
    </div>
    <div class="p-field" *ngIf="!selectedField.entityField?.unmodifiable">
      <p-checkbox [(ngModel)]="selectedField.required" [binary]="true" label="{{'view.configure.required' | translate}}"
        [disabled]="selectedField.readonly || selectedField.entityField?.unmodifiable"></p-checkbox>
    </div>
    <div class="p-field">
      <p-checkbox [(ngModel)]="selectedField.show_in_mobile" [binary]="true" label="{{'view.configure.show_mobile' | translate}}"></p-checkbox>
    </div>
    <!--<div class="p-field">
    <p-checkbox [(ngModel)]="selectedField.clear_both" [binary]="true" label="En nueva linea"></p-checkbox>
  </div>-->
    <div class="p-field" *ngIf="selectedField.entityField?.configuration?.actions!=null">
      <p-checkbox [(ngModel)]="selectedField.showActions" [binary]="true" label="{{'view.configure.show_actions' | translate}}"></p-checkbox>
    </div>
    <div class="p-field"
      *ngIf="selectedField.entityField?.navigation_entity_id == null && (selectedField.entityField?.data_type=='number' || selectedField.entityField?.data_type=='date')">
      <label>{{'view.configure.filter_operator' | translate}}</label>
      <div class="p-inputgroup">
        <p-dropdown name="filter_operator" [(ngModel)]="selectedField.filter_operator"
          [options]="selectedFieldOperators" placeholder="{{'view.configure.default' | translate}}" optionValue="code" optionLabel="label"
          appendTo="body"></p-dropdown>
      </div>
    </div>
    <div class="p-field">
      <label>{{'view.configure.visible_by' | translate}}</label>
      <div class="p-inputgroup">
        <p-dropdown name="visible_by" [(ngModel)]="selectedField.visible_by" [options]="visibleByOptions"
          optionValue="code" optionLabel="label" appendTo="body"></p-dropdown>
      </div>
    </div>
    <div class="p-field" *ngIf="!selectedField.readonly && !selectedField.entityField?.unmodifiable">
      <label>{{'view.configure.modify_by' | translate}}</label>
      <div class="p-inputgroup">
        <p-dropdown name="editable_by" [(ngModel)]="selectedField.editable_by" [options]="editableByOptions"
          optionValue="code" optionLabel="label" appendTo="body"></p-dropdown>
      </div>
    </div>
    <div class="p-grid p-mt-4 p-jc-center">
      <button pButton type="button" (click)="removeField(selectedField.entity_field_id)"
              class="p-button-danger">
        <fa-icon [icon]="['far','trash-alt']"></fa-icon>&nbsp;{{'view.configure.delete_field' | translate}}
      </button>
    </div>
  </ng-template>
</p-overlayPanel>


<p-overlayPanel appendTo="body" #opColumns [style]="{ width: '300px' }">
  <ng-template pTemplate>
    <p-accordion>
      <ng-template pTemplate="header">{{allowedEntity.name}}</ng-template>
      <p-accordionTab *ngFor="let allowedEntity of allowedEntities">
        <ng-template pTemplate="header">{{allowedEntity.name}}</ng-template>
        <ng-template pTemplate="content">
          <p-scrollPanel [style]="{width: '100%', height: '200px'}">
            <div *ngFor="let field of allowedEntitiesFields[allowedEntity.code]">
              <div *ngIf="componentService.isVisibleFieldCustom({ entityField: field }, null)" class="p-d-flex p-m-2">
                <p-checkbox [binary]="true" [(ngModel)]="field.selected" label="{{field.description}}"
                  (onChange)="onFieldChange($event, field)"></p-checkbox>
              </div>
            </div>
          </p-scrollPanel>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </ng-template>
</p-overlayPanel>
