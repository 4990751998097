import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { EntitiesService } from "../../../../configuration/entities.service";
import { MessageService } from "primeng/api";
import { OverlayPanel } from "primeng/overlaypanel";
import { DialogService } from "primeng/dynamicdialog";
import { FilterModalComponent } from "./modal/filter-modal.component";
import { DebugService } from "../../../debug.service";
import { ViewsService } from "../../views.service";
import { LoginService } from "../../../../login/login.service";
import { ViewsCustomService } from "../../views-custom.service";
import { SessionStorageService } from "ngx-webstorage";
import { filter } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ComponentService } from "../../component.service";


@Component({
  selector: 'app-view-component-filter',
  templateUrl: 'view-component-filter.component.html',
  styleUrls: ['view-component-filter.component.scss'],
})
export class ViewComponentFilterComponent implements OnInit {

  @Output() onEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('opFilter') opFilter: OverlayPanel;
  @Input("component") public component: any = {};
  @Input("index") public index: number = 0;
  @Input("model") public model: any = {};
  private model_original: any = {};
  @Input("config") public config: any = {};
  @Input("view") public view: any = {};

  
  public entity: any = {};
  public selectedEntitiesFieldsFilter: any[] = [];
  public temporalCombos: any = {};

  public  items = [
    {
      label: this.translateService.instant("general.filter.save_view"),// 'Guardar vista',
      icon: 'pi pi-save',
      command: () => {
        this.saveViewFilters();
      }
    },
    {
      label: this.translateService.instant("general.filter.create_new_view"),// 'Crear nueva vista',
      icon: 'pi pi-plus',
      command: () => {
        this.createViewFilters();
      }
    }
  ];
  public views_superadmin: boolean;
  public personalize_interface: boolean;
  public personalized_views_read: boolean;
  public personalized_views_write: boolean;
  public created_by: any;
  public allowViewPersonalize: boolean = false;

  constructor(
    private entitiesService: EntitiesService,
    private messageService: MessageService,
    private dialogService: DialogService,
    public debugService: DebugService,
    private viewsService: ViewsService,
    private loginService: LoginService,
    private viewsCustomService: ViewsCustomService,
    private sessionStorage: SessionStorageService,
    private translateService: TranslateService,
    public componentService: ComponentService
  ) {

    this.created_by = this.loginService.get("userId");
    this.personalize_interface = this.loginService.hasPermission("PERSONALIZE_INTERFACE");
    this.personalized_views_read = this.loginService.hasPermission("PERSONALIZED_VIEWS_READ");
    this.personalized_views_write = this.loginService.hasPermission("PERSONALIZED_VIEWS_WRITE");
    this.views_superadmin = this.loginService.hasPermission("VIEWS_SUPERADMIN") || localStorage.getItem("isSuperAdmin") == "true";
    this.allowViewPersonalize = this.view?.configuration?.personalizable != false ? true : false;

  }
  ngOnInit(): void {
    this.entitiesService.getByCode(this.component?.entity).subscribe(
      data => {
        this.entity = data;

        //guardamos una copia del modelo original
        this.model_original = JSON.parse(JSON.stringify(this.model));
        console.log(this.model_original);

        this.loadFiltersData();       
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    );
  }

  loadFiltersData() {
    var sessionInfo = this.sessionStorage.retrieve(this.view.code);
    var selectedFields = [];

    if(sessionInfo==null){
      //no habia session, cargamos campos del componente
      sessionInfo = { filters: [] };
      this.component.filters.filters.forEach((filter) => {
        let entityField = this.entity?.fields?.find(m => m.id == filter.entity_field_id);
        if (entityField != null) {
          sessionInfo.filters.push({ entity_field_id: filter.entity_field_id});
        }
      });
      this.sessionStorage.store(this.view.code, sessionInfo);
    }

    //añadir campos de filtros que esten en sesion

    let entities: any[] = [];
    sessionInfo.filters.forEach((filter) => {
      
        let entityField = this.entity?.fields?.find(m => m.id == Number(filter.entity_field_id));
        //console.log(entityField, this.model._excluded_fields);

        let isMetadata = false;
        if (entityField.model_property.indexOf("custom_") >= 0) isMetadata = true;
        let isFieldExcluded = this.model._excluded_fields?.indexOf(entityField.model_property) >= 0;
        if (entityField != null && !isFieldExcluded) {
          
          //calcular operador segun tipo de datos
          if (entityField?.control_type == "input-datetime" || entityField?.control_type == "input-number") {
            this.model["_op_" + entityField.model_property] = "between";
          }else if (entityField?.control_type == "input-text") {
            this.model["_op_" + entityField.model_property] = "like";
          } else if (entityField?.control_type == "dropdown" || entityField?.control_type == "dropdown-multiple") {
            if (isMetadata) {
              this.model["_op_" + entityField.model_property] = "=";
            } else {
              this.model["_op_" + entityField.model_property] = "in";
            }            
          } else if (entityField.control_type == "checkbox" && entityField.data_type == "boolean") {
            this.model["_op_" + entityField.model_property] = "=";

          } else {
            this.model["_op_" + entityField.model_property] = "==";
          }

          //cargamos combos
          if (entityField?.control_type == "dropdown" || entityField?.control_type == "dropdown-multiple") {
            //sacamos valor del combo
            if (entityField.configuration == null) return;
            if (entityField.configuration.options?.type == "api") {
              let params = "";
              //añadimos otros posibles parametros configurados
              if (entityField.configuration.options.params != null && Array.isArray(entityField.configuration.options.params)) {
                entityField.configuration.options.params.forEach(param => {
                  let value = eval(param.value);
                  if (typeof (value) != "undefined" && value != null && !isNaN(value)) params += (params != "" ? "&" : "") + param.name + "=" + value;
                });
              }
              if (params != "") params = "?" + params;

              this.debugService.log(this, "getComboOptions " + entityField.configuration.options.url + params);

              this.viewsService.getComboOptions(entityField.configuration.options.url + params).subscribe({
                next: (data: any) => {
                  this.temporalCombos[entityField.model_property] = data.rows;
                },
                error: (error: any) => {
                  this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
                }
              });
            } else {
              this.temporalCombos[entityField.model_property] = entityField.configuration.options.items;

            }
          }
          
          if (filter.hasOwnProperty("value") && filter.value != "" && filter.value != null) {
            if (entityField.control_type == "input-number") {
              //Componemos los numericos
              var auxValueNumber = filter.value.split("|");
              this.model[entityField.model_property + "_from"] = auxValueNumber[0];
              this.model[entityField.model_property + "_to"] = auxValueNumber[1];
            } /*else if (entityField.control_type == "input-datetime") {
              this.model[entityField.model_property] = filter.value;

            }*/
             else {
              this.model[entityField.model_property] = filter.value;
            }
            
          }

          //metemos la entidad
          entityField.entity = { code: entityField.entity_code, name: entityField.entity_name };
          selectedFields.push(entityField);
        }
      });

    this.selectedEntitiesFieldsFilter = selectedFields;
   
  }

  getComboOptions(entityField, selectValue = null, filterValue = null) {

    if (entityField.configuration == null) return;
    if (entityField.configuration.options?.type == "api") {
      let params = "";
      if (filterValue != "" && filterValue != null) params = "&searchText=" + filterValue;
      //añadimos otros posibles parametros configurados
      if (entityField.configuration.options.params != null && Array.isArray(entityField.configuration.options.params)) {
        entityField.configuration.options.params.forEach(param => {
          let value = eval(param.value);
          if (typeof (value) != "undefined" && value != null && !isNaN(value)) params += (params != "" ? "&" : "") + param.name + "=" + value;
        });
      }
      if (params != "") params = "?" + params;

      this.debugService.log(this, "getComboOptions " + entityField.configuration.options.url + params);

      this.viewsService.getComboOptions(entityField.configuration.options.url + params).subscribe({
        next : (data:any) => {
        this.temporalCombos[entityField.model_property] = data.rows;
      },
        error: (error:any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    }
  }

  onModelChange(value, field) {
    console.log(value, field);

    //  console.log(field);
    if (value != null && value.filter != "" && field.configuration?.options?.onModelChangeRefresh) {
      this.getComboOptions(field, null, value.filter);
    } else {
      this.getComboOptions(field);
    }
  }

  getLabel(field: any) {
    let label = field.label;
    if (label == "" || label == null) label = field.entityField?.description;
    return label;
  }

  openModalFilters(event: any) {

    console.log(JSON.stringify(this.model), JSON.stringify(this.model_original));

    const ref = this.dialogService.open(FilterModalComponent, {
      data: {
        selectedEntitiesFieldsFilter: this.selectedEntitiesFieldsFilter,
        fields: this.entity.fields.filter(m=>{
          return this.model._excluded_fields==null || this.model._excluded_fields.indexOf(m.model_property)<0;
        })
      },
      header: this.translateService.instant("general.filter.filter_fields") , //"Campos de búsqueda" ,
      width: '600px',
      styleClass: 'p-dialog-footer-sticky'
    });
    ref.onClose.subscribe((data: any) => {
      
      if (data != null){

        let sessionInfo = this.sessionStorage.retrieve(this.view.code);
        if(sessionInfo!=null){
          let finalFields:any[] = [];
          data.forEach(item => {
            let existsField = sessionInfo.filters.find(m=>m.entity_field_id==item);
            if(existsField){
              finalFields.push(existsField);
            }else{
              finalFields.push({ entity_field_id: item });
            }
          });
          sessionInfo.filters = finalFields;
          this.sessionStorage.store(this.view.code, sessionInfo);
          this.loadFiltersData();
          console.log(JSON.stringify(this.model), JSON.stringify(this.model_original));
        }
      }

    });
  }

  actionSearch() {
    this.saveFilters();

    this.viewsService.onFilterChange.emit();
    let eventPayload: any = { event: "action", valid: true, component: this.component.code, action: { name: "search" }, data: this.model };
    this.onEvent.emit(eventPayload);
  }

  resetFilters() {
    //limpiamos filtros del modelo
    let sessionInfo = this.sessionStorage.retrieve(this.view.code);
    console.log(sessionInfo);
    console.log(this.model);

    Object.entries(this.model).forEach(([key, value]) => {
      if(key.indexOf("_op_")<0){
        //buscamos valor en modelo original, por si hubiese algun filtro preestablecido por la pantalla
        var original_value = this.model_original[key];
        console.log(original_value);

        if(original_value!=null){
          this.model[key] = original_value;
        }else{
          this.model[key] = null;
          console.log("settting " + key + " to null");
        }
      }
    });

    //this.sessionStorage.clear(this.view.code);
    this.actionSearch();
  }

  saveViewFilters() {

    let sessionInfo = this.sessionStorage.retrieve(this.view.code);
    if (sessionInfo != null) {
      //Le añadimos los fitlros a la vista para guardarlos.
      if (this.component != null) {
        this.view.configuration.components?.forEach(component => {
          if (this.component.code == component.code) {
            component.filters = {};
            component.filters.filters = sessionInfo.filters;
            component.filters.allow_filter_panel = true;
          }
        });
      }
    }
    if (this.view.id) {
      this.viewsCustomService.add(this.view).subscribe({
        next: (data: any) => {
          this.messageService.add({ severity: "success", detail: this.translateService.instant("general.filter.save_filters") });
        },
        error: (error: any) => {
        this.messageService.add({ severity: "error", detail: "Error " + error });
      }
      });
      
    } else {
      this.viewsService.createView(this.component);
    }
    
  }
  createViewFilters() {
    let sessionInfo = this.sessionStorage.retrieve(this.view.code);
    this.component.filters.filters = sessionInfo.filters;
    this.viewsService.createView(this.component);
  }

  saveFilters() {
    //convertimos campos number a un rango
    let sessionInfo = this.sessionStorage.retrieve(this.view.code);
    sessionInfo.filters.forEach((filter) => {
      let entityField = this.entity?.fields?.find(m => m.id == filter.entity_field_id);
      if (entityField != null) {
        if (entityField.control_type == "input-number") {
          let from_value = this.model[entityField.model_property + "_from"];
          let to_value = this.model[entityField.model_property + "_to"];
          if (typeof from_value == "undefined" || from_value == "undefined" || from_value == null || from_value == "null") from_value = "";
          if (typeof to_value == "undefined" || to_value == "undefined" || to_value == null || to_value == "null") to_value = "";
          if (from_value != "" || to_value != "") {
            this.model[entityField.model_property] = from_value + "|" + to_value;
          } else {
            delete this.model[entityField.model_property];
          }
        }
        //pasamos valor a sesion, si no esta excluido
        let ignore_session_value = this.model._ignore_session_fields?.findIndex(m => m == entityField.model_property) >= 0;
        if (!ignore_session_value) {
          let filter_value = this.model[entityField.model_property];
          if (filter_value == null) {
            //comprobsmos si estaba preestablecido, lo reestablecemos de nuevo
            let original_value = this.model_original[entityField.model_property];
            if (original_value != null) {
              filter_value = original_value;
              this.model[entityField.model_property] = this.model_original[entityField.model_property];
            }
          }
          filter.value = filter_value;
          filter.operator = this.model["_op_" + entityField.model_property];
        }

      }
    });
    this.sessionStorage.store(this.view.code, sessionInfo);
  }
  /*

  getFieldDescription(field: any, entityfieldObject: boolean = true) {
    let label = (entityfieldObject ? field.entityField?.description : field.description);
    if (field.label && field.label != null && field.label != "") {
      label = this.translateService.instant(field.label);
    } else {

      //No es parsear la descripción sino componer la clave. EJEM: contact.entity_fields.name
      let full_model_property = (entityfieldObject ? field.entityField?.model_property : field.model_property);
      let model_property = full_model_property.split("."); //Porque si son de otra entidad a la pintada el model property llega concatenado.
      let keyTranslate = (entityfieldObject ? field.entityField?.entity_code : field.entity_code) + ".entity_fields." + model_property[model_property.length - 1];
      if (!entityfieldObject) console.log(keyTranslate, field);

      let label2 = this.translateService.instant(keyTranslate);
      if (keyTranslate != label2) {
        //No ha encontrado la clave, ponemos la descripción del campo.
        label = label2;
      }

    }
    return label;
  }
  getEntityName(entity: any) {
    let label = null;

    //Buscamos si está en la traducción key = "contact.entity"
    let key = entity.code + ".entity.name";
    label = this.translateService.instant(key);
    if (key == label) {
      //No tiene traduccion devolvemos la base.
      label = entity.name;
    }
    return label;
  }*/

}
