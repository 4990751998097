<form [formGroup]="myForm" (ngSubmit)="onSubmit()">
  <p-card styleClass="p-card p-component">
    <div class="p-grid  p-grid-nogutter">
      <div class="p-col-12 p-field p-mt-3">
        <label for="name">{{'component.reports.detail.records_to_create_task' | translate}}</label>
        <p-table #grid [lazy]="true" [value]="rows" [columns]="results.labels" [paginator]="false"
                 [totalRecords]="results.length" [reorderableColumns]="false" styleClass="p-datatable-sm" [scrollable]="true"
                 scrollHeight="150px" class="p-mt-3">
          <ng-template pTemplate="body" let-item let-index="rowIndex" let-rowData>
            <tr [pReorderableRow]="index">
              <td width="25">
                <p-checkbox name="groupname" value="{{item.base_id}}" [inputId]="item.base_id"
                            [(ngModel)]="selected" [ngModelOptions]="{standalone: true}"></p-checkbox>
              </td>
              <td *ngFor="let header of results.labels"
                  [innerHTML]="getRowValue(header, item[header.column_key], item )">
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [attr.colspan]="results.values.length + 1">
                {{'general.not_data' | translate }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="p-field p-mb-0 p-pb-0 p-md-12">
        <label for="task_action_id">{{'component.reports.detail.type' | translate}}</label>
        <p-dropdown name="task_action_id" [showClear]="true" [options]="tasksActionTypes" optionLabel="name"
                    optionValue="id" placeholder="{{'general.select' | translate}}" appendTo="body" required formControlName="task_action_id"
                    [ngClass]="{ 'invalid': myForm.get('task_action_id').invalid && myForm.get('task_action_id').touched }"></p-dropdown>
      </div>
      <div class="p-field p-mb-0 p-pb-0 p-md-12">
        <label for="task_action_description">{{'component.reports.detail.description' | translate}}</label>
        <textarea name="task_action_description" rows="2" style="width: 100%; resize: vertical;"
                  [ngClass]="{ 'invalid': myForm.get('task_action_description').invalid && myForm.get('task_action_description').touched }"
                  formControlName="task_action_description"></textarea>
      </div>
      <div class="p-field p-mb-0 p-pb-0 p-md-12">
        <label for="responsible">{{'component.reports.detail.responsible' | translate}}</label>
        <p-dropdown name="responsible" [showClear]="true" [options]="responsibles" optionLabel="name" optionValue="id"
                    placeholder="-" formControlName="responsible_user_id"
                    [ngClass]="{ 'invalid': myForm.get('responsible_user_id').invalid && myForm.get('responsible_user_id').touched }"></p-dropdown>
      </div>
      <div class="p-field p-mb-0 p-pb-0 p-md-6">
        <label for="task_action_scheduled_date">{{'component.reports.detail.datetime_init' | translate}}</label>
        <p-calendar name="task_action_scheduled_date" showTime="true" hourFormat="24" dateFormat="dd/mm/yy"
                    appendTo="body" [stepMinute]="5" formControlName="task_action_scheduled_date"
                    [inputStyleClass]="getCalendarInputClass(0)">
        </p-calendar>
      </div>
      <div class="p-field p-mb-0 p-pb-0 p-md-6">
        <label for="task_action_scheduled_date">{{'component.reports.detail.end_date' | translate}}</label>
        <p-calendar name="task_action_scheduled_date" dateFormat="dd/mm/yy" appendTo="body" [stepMinute]="5"
                    formControlName="task_action_scheduled_date_end"
                    [inputStyleClass]="getCalendarInputClass(1)"></p-calendar>
      </div>
      {{'component.reports.detail.records_to_send_email' | translate}}
    <small class="p-mb-0 p-pb-0 p-md-12">{{create_multiple_task}}</small><br>
      <small class="p-mb-0 p-pb-0 p-md-12">{{getTotalPerDay()}}</small>
    </div>
  </p-card>

  <div class="p-d-flex p-mt-3">
    <div class="flex-grow-1"></div>
    <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times" styleClass="p-button-secondary p-button-text" (click)="cancel()"></p-button>
    <p-button label="{{'component.reports.detail.create_task' | translate}}" icon="pi pi-check" styleClass="p-ml-2" (click)="onSubmit()"></p-button>
  </div>
</form>
