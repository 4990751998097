<p-messages severity="info" class="p-d-block">
  <ng-template pTemplate>
    {{'component.integrations.sage_info'|translate}}
  </ng-template>
</p-messages>

<p-accordion>
  <p-accordionTab *ngFor="let config of integration.configuration.business" [disabled]="config.business_code==null">
    <ng-template pTemplate="header">
      <div class="p-d-flex p-pr-4" style="width: 100%;">
        <div class="flex-grow-1">{{config.business_name}}</div>
        <div *ngIf="config.business_code!=null" class="p-badge">{{'component.integrations.sage_code'|translate}}:
          {{config.business_code}}</div>
        <div *ngIf="config.business_code==null" class="p-badge p-badge-danger">
          {{'component.integrations.sage_code_not_configured'|translate}}</div>
      </div>
    </ng-template>
    <div class="p-grid">
      <div class="p-col-12">
        <p-card>
          <ng-template pTemplate="header">
            <div class="p-card-title">
              {{'component.integrations.clients'|translate}}
            </div>
          </ng-template>
          <div class="p-grid">

            <div class="p-col-4 p-field">
              <label>{{'component.integrations.bring_from_sage'|translate}}</label>
              <p-checkbox [(ngModel)]="config.import_clients" binary="true" name="import_clients"
                [ngModelOptions]="{standalone: true}"></p-checkbox>
            </div>
            <div class="p-col-4 p-field" *ngIf="config.import_clients">
              <label>{{'component.integrations.assign_type'|translate}}</label>
              <p-dropdown name="organization_type_id" [(ngModel)]="config.organization_type_id"
                [options]="organizationsTypes" optionValue="id" optionLabel="name" appendTo="body"></p-dropdown>
            </div>
            <div class="p-col-4 p-field" *ngIf="config.import_clients">
              <label>{{'component.integrations.import_balance'|translate}}</label>
              <p-checkbox [(ngModel)]="config.import_clients_totals" binary="true" name="import_articles"
                [ngModelOptions]="{standalone: true}"></p-checkbox>
            </div>

            <div class="p-col-4 p-field">
              <label>{{'component.integrations.send_to_sage'|translate}}</label>
              <p-checkbox [(ngModel)]="config.export_clients" binary="true" name="export_clients"
                [ngModelOptions]="{standalone: true}"></p-checkbox>
            </div>

          </div>

        </p-card>
      </div>
      <div class="p-col-12">
        <p-card>
          <ng-template pTemplate="header">
            <div class="p-card-title">
              {{'contact.entity.name_plural'|translate}}
            </div>
          </ng-template>
          <div class="p-grid">
            <div class="p-col-6 p-field">
              <label>{{'component.integrations.bring_from_sage'|translate}}</label>
              <p-checkbox [(ngModel)]="config.import_contacts_clients" binary="true" name="import_contacts_clients"
                [ngModelOptions]="{standalone: true}"></p-checkbox>
            </div>
            <div class="p-col-6 p-field">
              <label>{{'component.integrations.send_to_sage'|translate}}</label>
              <p-checkbox [(ngModel)]="config.export_contacts_clients" binary="true" name="export_contacts_clients"
                [ngModelOptions]="{standalone: true}"></p-checkbox>
            </div>
          </div>
        </p-card>
      </div>

      <div class="p-col-12">
        <p-card>
          <ng-template pTemplate="header">
            <div class="p-card-title">
              {{'component.integrations.articles'|translate}}
            </div>
          </ng-template>
          <div class="p-grid">
            <div class="p-col-6 p-field">
              <label>{{'component.integrations.bring_from_sage'|translate}}</label>
              <p-checkbox [(ngModel)]="config.import_articles" binary="true" name="import_articles"
                [ngModelOptions]="{standalone: true}"></p-checkbox>
            </div>
            <div class="p-col-6 p-field">
              <label>{{'component.integrations.update_stock'|translate}}</label>
              <p-checkbox [(ngModel)]="config.import_articles_stock" binary="true" name="import_articles_stock"
                [ngModelOptions]="{standalone: true}"></p-checkbox>
            </div>
          </div>
        </p-card>
      </div>

      <!--<div class="p-col-12">
        <p-checkbox label="Importar facturas de SAGE" [(ngModel)]="config.import_invoices" binary="true" name="import_invoices" [ngModelOptions]="{standalone: true}"></p-checkbox>
      </div>
      <div class="p-col-4 p-field" *ngIf="config.import_invoices">
        <label>Tipo de transacción</label>
        <p-dropdown name="invoices_transaction_type_id"
                    [(ngModel)]="config.invoices_transaction_type_id"
                    [options]="transactionTypes"
                    (onChange)="onTransactionTypeChange()"
                    optionValue="id"
                    optionLabel="name"
                    appendTo="body"></p-dropdown>
      </div>
      <div class="p-col-4 p-field" *ngIf="config.import_invoices">
        <label>Estado</label>
        <p-dropdown name="invoices_transaction_status_id"
                    [(ngModel)]="config.invoices_transaction_status_id"
                    [options]="transactionStatus"
                    optionValue="id"
                    optionLabel="name"
                    appendTo="body"></p-dropdown>
      </div>-->

      <div class="p-col-12">
        <p-card>
          <ng-template pTemplate="header">
            <div class="p-card-title">
              {{'sale.entity.name_plural' |translate}}
            </div>
          </ng-template>
          <div class="p-grid">
            <div class="p-col-6 p-field">
              <label>{{'component.integrations.send_to_sage'|translate}}</label>
              <p-checkbox [(ngModel)]="config.sales.export" binary="true" name="export_sales"
                [ngModelOptions]="{standalone: true}"></p-checkbox>
            </div>
            <div class="p-col-6 p-field">
              <label>{{'component.integrations.keep_axialcrm_price'|translate}}</label>
              <p-checkbox [(ngModel)]="config.sales.use_axial_prices" binary="true" name="use_axial_prices"
                [ngModelOptions]="{standalone: true}"></p-checkbox>
            </div>
          </div>
        </p-card>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>

<div class="p-mt-4 p-grid">
  <div class="p-col-12 p-d-flex">
    <div class="flex-grow-1"></div>
    <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
      styleClass="p-button-secondary p-button-text" (click)="cancel()">
    </p-button>
    <button class="p-ml-2" style="float: right;" icon="pi pi-save" pButton type="button"
      label="{{'general.action.save' | translate}}" (click)="save()"></button>
  </div>
</div>