import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EntitiesService } from 'src/app/configuration/entities.service';
import { ContactsRoutingModule } from 'src/app/contacts/contacts-routing.module';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ReportsService } from '../../reports.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reports-ask-user',
  templateUrl: './reports-ask-user.component.html',
  styleUrls: ['./reports-ask-user.component.scss']
})
export class ReportsAskUserComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private config: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private coreDialogService: CoreDialogService,
    private datePipe: DatePipe,
    private reportsService: ReportsService,
    private entitiesService: EntitiesService,
    private translateService: TranslateService
  ) { }

  public filterValuesOptions: any[] = [];
  public filterConditionsValues: any[] = []
  public temporalCombo: any[] = [];

  public filters: any = {};
  public temporalCombos: any = {};
  public filter_options = [
    { name: this.translateService.instant("component.reports.detail.>"), value: '>' },
    { name: this.translateService.instant("component.reports.detail.<"), value: '<' },
    { name: this.translateService.instant("component.reports.detail.="), value: '=' },
  ];
  public date_options = [
    { name: this.translateService.instant("component.reports.detail.date_select"), value: 'date' },
    { name: this.translateService.instant("component.reports.detail.date_now"), value: 'date_now' },
    { name: this.translateService.instant("component.reports.detail.datetime_now"), value: 'datetime_now' },
    { name: this.translateService.instant("component.reports.detail.this_week"), value: 'this_week' },
    { name: this.translateService.instant("component.reports.detail.next_week"), value: 'next_week' },
    { name: this.translateService.instant("component.reports.detail.prev_week"), value: 'prev_week' },
    //{ name: 'Esta quincena', value: 'this_fortnight' },
    //{ name: 'Siguiente quincena', value: 'next_fortnight' },
    { name: this.translateService.instant("component.reports.detail.this_month"), value: 'this_month' },
    { name: this.translateService.instant("component.reports.detail.next_month"), value: 'next_month' },
    { name: this.translateService.instant("component.reports.detail.prev_month"), value: 'prev_month' },
    { name: this.translateService.instant("component.reports.detail.this_quarter"), value: 'this_quarter' },
    { name: this.translateService.instant("component.reports.detail.next_quarter"), value: 'next_quarter' },
    { name: this.translateService.instant("component.reports.detail.prev_quarter"), value: 'prev_quarter' },
    { name: this.translateService.instant("component.reports.detail.this_semester"), value: 'this_semester' },
    { name: this.translateService.instant("component.reports.detail.next_semester"), value: 'next_semester' },
    { name: this.translateService.instant("component.reports.detail.prev_semester"), value: 'prev_semester' },
    { name: this.translateService.instant("component.reports.detail.this_year"), value: 'this_year' },
    { name: this.translateService.instant("component.reports.detail.next_year"), value: 'next_year' },
    { name: this.translateService.instant("component.reports.detail.prev_year"), value: 'prev_year' }
  ];
  public entitiesToFilter: any[] = [];
  public entity: any = [];

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.loadData(this.config.data.filters, this.config.data.entity);

    });   
  }

  loadData(filters, entity) {
    this.entity = entity;
    this.entity.fields.forEach(field => {
      //Creamos el array de los filtros porque no se puede realizar una llamada (getFilterOptionsOrValueType), ya que hay que darle dos veces para seleccionarlo.
      if (typeof this.filterConditionsValues[field.id] == "undefined") this.filterConditionsValues[field.id] = [];
      this.filterConditionsValues[field.id] = this.filterOptions(field.data_type, field.control_type, field);
    });
    this.filters = filters;


    this.filters.filtersGroupAND.forEach(elementAND => {
      elementAND.ask_user = false;
      elementAND.filtersGroupOR.forEach(element => {
        if (element.ask_user) {
          elementAND.ask_user = true;
        }
        let field = this.entity.fields.filter(m => m.id == element.entity_field_id);

        //cargamos en un temporal todos los datos de  los posibles combos
        if (field[0].control_type == "dropdown" || field[0].control_type == "dropdown-multiple") {
          this.getComboOptions(field[0], null);
        }
      });
    });
  }

  getComboOptions(field, text) {
    if (field.configuration.options?.type == "api") {
      var url = "/api/" + field.configuration.options.url;
      if (text == "undefined") text = null;
      this.temporalCombo[field.id] = [];
      this.reportsService.customVoidCombos(url, { text: text }).subscribe({
        next: (data: any) => {

          this.temporalCombo[field.id] = data.rows;
          if (field.configuration.options.url == "users/combo" || field.configuration.options.url == "users") {
            //Añadimor el usuario actual
            this.temporalCombo[field.id].push({ id: -1, name: this.translateService.instant("general.actual_user") });
          }
          if (field.configuration.options.url == "transactionsStatus") {
            let transaction_type_id = (field.entity_code == "oportunity" ? 1 : 2);
            this.temporalCombo[field.id] = this.temporalCombo[field.id].filter(function (e) { return e.transaction_type_id == transaction_type_id });
          }
          //

          if (field.data_type == "list" || field.data_type == 'list_multiple') {
            field.configuration.options.items.forEach(element => {
              let id = field.configuration.field_value;
              let text = field.configuration.field_text;
              if (typeof this.filterValuesOptions[element.entity_field_id] === "undefined") this.filterValuesOptions[element.entity_field_id] = [];
              this.filterValuesOptions[element.entity_field_id].push({ [id]: element.code, [text]: element.text });
            });
          } else if (field.data_type == "number") {
            //if (typeof this.filterValuesOptions[field.id] === "undefined") 
            this.filterValuesOptions[field.id] = [];
            this.temporalCombo[field.id].forEach(element => {

              element.text = element[field.configuration.field_text];
              element.code = element[field.configuration.field_value];
              this.filterValuesOptions[field.id].push(element);
            })
          }
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      });
    } else {
      field.configuration.options.items.forEach(e => {
        e.name = e.text;
        e.id = e.code;
      })
      this.temporalCombo[field.id] = field.configuration.options.items
      if (typeof this.filterValuesOptions[field.id] === "undefined") this.filterValuesOptions[field.id] = [];
      this.temporalCombo[field.id].forEach(element => {
        this.filterValuesOptions[field.id].push(element);
      })
    }

  }

  onFilter(event, groupOr) {
    var ent = this.entity.fields.filter(m => m.id == groupOr.entity_field_id)[0];
    if (event.filter !== null && event.filter.length >= 3) this.getComboOptions(ent, event.filter);
    if (event.filter === null) this.getComboOptions(ent, "");
  }

  getFilterOptionsOrValueType(element, filter) {
    if (typeof (this.entity.fields) !== "undefined") {
      let field = this.entity.fields.filter(m => m.id == element.entity_field_id);
      if (field.length > 0) {
        //if(filter) return this.filterOptions(field[0].data_type, field[0].control_type, field[0]);
        if (!filter) return this.valueTypes(field[0].data_type, field[0].control_type);
      }
    }
  }
  filterOptions(data_type: string, control_type: string, element: any) {

    let filter_options: any;
    if (data_type == "string") {
      filter_options = [
        { name: this.translateService.instant("component.reports.detail.contains"), value: 'contains' },
        { name: this.translateService.instant("component.reports.detail.="), value: '=' },
        { name: this.translateService.instant("component.reports.detail.<>"), value: '<>' },
        { name: this.translateService.instant("component.reports.detail.null"), value: 'null' },
        { name: this.translateService.instant("component.reports.detail.!null"), value: '!null' }
      ];
    }
    else if ((data_type == "number" && control_type != "dropdown") || data_type == "date") {
      filter_options = [
        { name: this.translateService.instant("component.reports.detail.>"), value: '>' },
        { name: this.translateService.instant("component.reports.detail.>="), value: '>=' },
        { name: this.translateService.instant("component.reports.detail.<"), value: '<' },
        { name: this.translateService.instant("component.reports.detail.<="), value: '<=' },
        { name: this.translateService.instant("component.reports.detail.="), value: '=' },
        { name: this.translateService.instant("component.reports.detail.<>"), value: '<>' },
        { name: this.translateService.instant("component.reports.detail.null"), value: 'null' },
        { name: this.translateService.instant("component.reports.detail.!null"), value: '!null' }
      ];
    }
    else if (data_type == "list") {
      filter_options = [
        { name: this.translateService.instant("component.reports.detail.="), value: '=' },
        { name: this.translateService.instant("component.reports.detail.<>"), value: '<>' },
        { name: this.translateService.instant("component.reports.detail.null"), value: 'null' },
        { name: this.translateService.instant("component.reports.detail.!null"), value: '!null' }
      ];
    }
    else if (data_type == "list_multiple") {
      filter_options = [
        { name: this.translateService.instant("component.reports.detail.contains"), value: 'contains' },
        { name: this.translateService.instant("component.reports.detail.="), value: '=' },
        { name: this.translateService.instant("component.reports.detail.<>"), value: '<>' },
        { name: this.translateService.instant("component.reports.detail.null"), value: 'null' },
        { name: this.translateService.instant("component.reports.detail.!null"), value: '!null' }
      ];
    }
    else if (data_type == "number" && control_type == "dropdown") {
      //para las forenkeys... 
      filter_options = [
        { name: this.translateService.instant("component.reports.detail.="), value: '=' },
        { name: this.translateService.instant("component.reports.detail.<>"), value: '<>' },
        { name: this.translateService.instant("component.reports.detail.null"), value: 'null' },
        { name: this.translateService.instant("component.reports.detail.!null"), value: '!null' }
      ];
    } else {
      //boolean
      filter_options = [
        { name: this.translateService.instant("component.reports.detail.="), value: '=' },
        { name: this.translateService.instant("component.reports.detail.<>"), value: '<>' }
      ];
    }
    return filter_options;
  }

  valueTypes(data_type: string, control_type: string) {
    let valueType = "";
    if (data_type == "string") {
      valueType = 'input-text';
    }
    else if ((data_type == "number" && control_type != "dropdown") || data_type == "date") {
      valueType = (data_type == "number" ? 'input-number' : "input-datetime");
    }
    else if (data_type == "list") {
      valueType = "list";
    }
    else {
      valueType = 'input-text';
    }

    if (control_type == "dropdown") {
      valueType = 'input-dropdown';
    }
    if (control_type == "dropdown-multiple") {
      valueType = "dropdown-multiple";
    }
    return valueType;
  }

  getFilterComboOptionsText(element: any, field_text: boolean) {
    let field = this.entity.fields.filter(m => m.id == element.entity_field_id);
    if (field.length > 0) {
      if (field_text) {
        return field[0].configuration.field_text;
      } else {
        return field[0].configuration.field_value;
      }
    }
  }

  getFilterOptionsData(element: any) {

    let filterOptions: any[] = [];
    let field = this.entity.fields.filter(m => m.id == element.entity_field_id);
    if (field.length > 0) {
      if (field[0].data_type == "list") {
        field[0].configuration.options.items.forEach(element => {
          filterOptions.push({ id: element.code, name: element.text });
        });
      } else if (field[0].data_type == "number") {
        return this.temporalCombos[element.entity_field_id];
      }
    }
    return filterOptions;
  }
  getNameField(field) {
    let field2 = this.entity.fields.find(m => m.id == field.entity_field_id);
    if (field2 != null) {
      return field2.description;
    } else {
      return "";
    }
  }

  getOptions(filter) {
    if (filter.entity_field_type == "string") {
      this.filter_options = [
        { name: this.translateService.instant("component.reports.detail.contains"), value: 'contains' },
        { name: this.translateService.instant("component.reports.detail.="), value: '=' },
        { name: this.translateService.instant("component.reports.detail.<>"), value: '<>' },
        { name: this.translateService.instant("component.reports.detail.null"), value: 'null' },
        { name: this.translateService.instant("component.reports.detail.!null"), value: '!null' }
      ];
    }
    else if ((filter.entity_field_type == "number" && filter.entity_field_control_type != "dropdown") || filter.entity_field_type == "date") {
      this.filter_options = [
        { name: this.translateService.instant("component.reports.detail.>"), value: '>' },
        { name: this.translateService.instant("component.reports.detail.>="), value: '>=' },
        { name: this.translateService.instant("component.reports.detail.<"), value: '<' },
        { name: this.translateService.instant("component.reports.detail.<="), value: '<=' },
        { name: this.translateService.instant("component.reports.detail.="), value: '=' },
        { name: this.translateService.instant("component.reports.detail.<>"), value: '<>' },
        { name: this.translateService.instant("component.reports.detail.null"), value: 'null' },
        { name: this.translateService.instant("component.reports.detail.!null"), value: '!null' }
      ];
    }
    else if (filter.entity_field_type == "list") {
      this.filter_options = [
        { name: this.translateService.instant("component.reports.detail.="), value: '=' },
        { name: this.translateService.instant("component.reports.detail.<>"), value: '<>' },
        { name: this.translateService.instant("component.reports.detail.null"), value: 'null' },
        { name: this.translateService.instant("component.reports.detail.!null"), value: '!null' }
      ];
    }
    else if (filter.entity_field_type == "number" && filter.entity_field_type == "dropdown") {
      //para las forenkeys... 
      this.filter_options = [
        { name: this.translateService.instant("component.reports.detail.="), value: '=' },
        { name: this.translateService.instant("component.reports.detail.<>"), value: '<>' },
        { name: this.translateService.instant("component.reports.detail.null"), value: 'null' },
        { name: this.translateService.instant("component.reports.detail.!null"), value: '!null' }
      ];
    }
    return this.filter_options;
  }

  save() {

    this.filters.filtersGroupAND.forEach(elementAND => {
      elementAND.filtersGroupOR.forEach(element => {
        if (element.entity_field_type == "date") {
          element.value = this.datePipe.transform(element.value, "yyyy-MM-dd HH:mm");
        }

      });
    });

    this.dynamicDialogRef.close(this.filters);
  }
  cancel() {
    this.filters.filtersGroupAND.forEach(elementAND => {
      elementAND.filtersGroupOR.forEach(element => {
        if (element.entity_field_type == "date") {
          element.value = this.datePipe.transform(element.value, "yyyy-MM-dd HH:mm");
        }

      });
    });
    this.dynamicDialogRef.close(this.filters);
  }

  dateToIso($event: Date, i: number, j: number) {
    this.filters.filtersGroupAND[i].filtersGroupOR[j].value = new Date($event.setSeconds(0)).toISOString();// $event.toISOString();
  };
}
