import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { EntitiesService } from 'src/app/configuration/entities.service';
import { OrganizationsTypesService } from 'src/app/organizations/organizations-types.service';
import { MenuItem } from 'primeng/api';
import { TransactionTypesService } from 'src/app/configuration/transaction-types/transaction-types.service';
import { NgEventBus } from 'ng-event-bus';
import { MetaData } from 'ng-event-bus/lib/meta-data';
import { LoginService } from 'src/app/login/login.service';
import { forkJoin } from 'rxjs';
import { VersionService } from '../version.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss'],
})
export class SidebarComponent {

  public version: any = {};
  public organizationTypes: any = Array();
  public transactionTypes: any = Array();
  public items: MenuItem[];
  public isMobile: boolean = false;
  public isOpen: boolean = true;
  public titleItemsMenu: any;
  public entities: any[];
  public firstTimeResize: boolean = true;

  constructor(
    private entitiesService: EntitiesService,
    private versionService: VersionService,
    private organizationTypesService: OrganizationsTypesService,
    private transactionTypesService: TransactionTypesService,
    private loginService: LoginService,
    private messageService: MessageService,
    private router: Router,
    private eventBus: NgEventBus,
    private translateService: TranslateService
  ) {
    this.getVersion();
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.entitiesService.all({}).subscribe(
      data => {
        this.entities = data.rows;
        this.loadData();
      },
      error => {
        console.log("ha ocurrido un error...", error);
      }
    );


    //escuchamos eventos
    this.eventBus.on('sidebar:toogle').subscribe((meta: MetaData) => {
      if (meta.data != null) {
        this.isOpen = meta.data;
      } else {
        this.isOpen = !this.isOpen;
      }
    });

    //calculamos ancho de pantalla para ver si cerramos menu en caso de movil
    var ratio = window.devicePixelRatio || 1;
    //var w = screen.width / ratio;
    var w = window.innerWidth / ratio;
    if (w <= 768) {
      //cerramos menu
      this.isMobile = true;
      this.eventBus.cast('sidebar:toogle', false);
    }

    //escuchamos cambios de estado de login
    this.loginService.onLoginStateChange.subscribe(authenticated => {
      if (authenticated) this.loadData()
    });


    //Hacmos esto para gestionar si lo que hacen es cambiar el tamaño de la pantalla.
    let that = this;
    window.addEventListener("resize", function (event) {
      var w = window.innerWidth / ratio;
      if (w <= 768 && that.firstTimeResize) {
        //cerramos menu
        that.isMobile = true;
        that.eventBus.cast('sidebar:toogle', false);
        that.firstTimeResize = false;
      } else {
        if (w >= 768) {
          that.firstTimeResize = true;
        }
      }
    })
  }


  closeMenu() {
    this.clearLocalStorageTasksFilters();
    if (this.isMobile) this.eventBus.cast('sidebar:toogle', false);
  }

  clearLocalStorageTasksFilters() {
    localStorage.removeItem("tasks.list");
    localStorage.removeItem("organization.tasks");
    localStorage.removeItem("home.tasks");
  }

  loadData() {
    //precargamos datos necesarios
    this.loadOrganizationsTypes(() => {
      this.loadTransactionTypes(() => {
        //generamos menu
        this.prepareMenuItems();
      });
    })
  }
  getMenuGrouped(group) {
    return this.items.filter(m => m.title == group);
  }
  loadOrganizationsTypes(cbFn: Function) {
    var organizationsMenu = [{
      label: this.translateService.instant("menu.all"),
      routerLink: ['/organizations'],
      routerLinkActiveOptions: { exact: true },
      icon: 'pi pi-fw pi-circle-on color-white',
      command: (event) => { this.closeMenu(); }
    }];
    this.organizationTypesService.all({}).subscribe(
      data => {
        this.organizationTypes = data;
        cbFn();
      },
      error => { }
    )
    return organizationsMenu;
  }

  loadTransactionTypes(cbFn: Function) {
    var transactionsMenu = [];
    this.transactionTypesService.all({}).subscribe(
      data => {
        this.transactionTypes = data;
        cbFn();
      },
      error => { }
    )
    return transactionsMenu;
  }

  prepareMenuItems() {
    this.titleItemsMenu = [
      this.translateService.instant("menu.menu").toUpperCase(),
      this.translateService.instant("menu.action").toUpperCase(),
      this.translateService.instant("menu.documents").toUpperCase(),
      this.translateService.instant("menu.support").toUpperCase()
    ];

    //items de tipos de organizacion
    let organizationsMenu: MenuItem[] = [];
    organizationsMenu.push({
      label: this.translateService.instant("menu.all"),
      routerLink: ['/organizations'],
      routerLinkActiveOptions: { exact: true },
      icon: 'pi pi-fw pi-circle-on color-white',
      command: (event) => { this.closeMenu(); }
    });
    this.organizationTypes.rows.forEach(element => {
      organizationsMenu.push({
        label: element.name,
        routerLink: ['/organizations', element.id],
        routerLinkActiveOptions: { exact: true },
        icon: 'pi pi-fw pi-circle-on organization-type-color-' + element.id,
        command: (event) => { this.closeMenu(); }
      })
    });
    organizationsMenu.push({
      label: this.translateService.instant("menu.no_type"),
      routerLink: ['/organizations', "0"],
      routerLinkActiveOptions: { exact: true },
      icon: 'pi pi-fw pi-circle-off',
      command: (event) => { this.closeMenu(); }
    });

    //items de tipos de transaction
    let transactionsMenu: MenuItem[] = [];
    this.transactionTypes.rows.forEach(element => {
      transactionsMenu.push({
        label: element.name,
        routerLink: ['/transactions', element.id],
        routerLinkActiveOptions: { exact: true },
        icon: '',
        command: (event) => { this.closeMenu(); }
      })
    });

    this.items = [
      {
        title: this.translateService.instant("menu.menu").toUpperCase(),
        label: this.translateService.instant("general.breadcrumb.home"),
        icon: 'pi pi-fw pi-home',
        routerLink: ['/home'],
        routerLinkActiveOptions: { exact: true },        //style: "padding: 15px !important;" 
        command: (event) => { this.closeMenu(); },
      },
      {
        title: this.translateService.instant("menu.menu").toUpperCase(),
        label: this.getName("organizations", 'Organizaciones', ''),
        icon: ' pi pi-fw pi-briefcase',
        items: organizationsMenu,
        visible: this.loginService.hasPermission("ORGANIZATIONS_READ"),
      },/*
      {
        label: 'Usuarios',
        icon: 'pi pi-fw pi-user',
        routerLink: ['/users'],
        command: (event) => { this.closeMenu(); }
      },*/
      {
        title: this.translateService.instant("menu.menu").toUpperCase(),
        label: this.getName("contacts", 'Contactos', ''),
        icon: 'pi pi-fw pi-user',
        routerLink: ['/contacts'],
        command: (event) => { this.closeMenu(); },
        visible: this.loginService.hasPermission("CONTACTS_READ")
      },
      {
        title: this.translateService.instant("menu.menu").toUpperCase(),
        label: this.translateService.instant("component.organizations.meetings_detail.calendar"),
        icon: 'pi pi-fw pi-calendar',
        routerLink: ['/calendar'],
        command: (event) => { this.closeMenu(); },
        visible: this.loginService.hasPermission("CALENDAR")
        //visible: this.loginService.hasPermission("CALENDAR_LINK")
      },
      {
        title: this.translateService.instant("menu.action").toUpperCase(),
        label: this.getName("tasks", 'Actividades', ''),
        icon: 'pi pi-fw pi-list',
        routerLink: ['/tasks'],
        command: (event) => { this.closeMenu(); },
        visible: this.loginService.hasPermission("TASKS_READ")
      },
      {
        title: this.translateService.instant("menu.action").toUpperCase(),
        label: this.getName("transactions", 'Oportunidades', 'oportunity'),
        icon: 'pi pi-fw pi-money-bill',
        routerLink: ['/transactions/1'],
        command: (event) => { this.closeMenu(); },
        visible: this.loginService.hasPermission("OPORTUNITIES_READ")
      },
      {
        title: this.translateService.instant("menu.action").toUpperCase(),
        label: this.getName("products", 'Productos', ''),
        icon: 'pi pi-fw pi-shopping-cart',
        routerLink: ['/products'],
        command: (event) => { this.closeMenu(); },
        visible: this.loginService.hasPermission("PRODUCTS_MENU")
      },
      {
        title: this.translateService.instant("menu.action").toUpperCase(),
        label: this.getName("transactions", 'Ventas', 'sale'),
        icon: 'pi pi-fw pi-dollar',
        routerLink: ['/transactions/2'],
        command: (event) => { this.closeMenu(); },
        visible: this.loginService.hasPermission("SALES_READ")
      },
      {
        title: this.translateService.instant("menu.action").toUpperCase(),
        label: this.getName("transactions", 'Transacciones', 'transaction'),
        icon: 'pi pi-fw pi-sort-alt',
        items: transactionsMenu,
        visible: this.loginService.hasPermission("TRANSACTIONS_MENU")
      },
      {
        title: this.translateService.instant("menu.documents").toUpperCase() ,
        label: this.translateService.instant("component.reports.detail.reports"),
        icon: 'pi pi-fw pi-copy',
        routerLink: ['/reports'],
        command: (event) => { this.closeMenu(); },
        visible: this.loginService.hasPermission("REPORTS_READ")
      },
      {
        title: this.translateService.instant("menu.documents").toUpperCase(),
        label: this.getName("attachments", 'Adjuntos', ''),
        icon: 'pi pi-fw pi-folder',
        routerLink: ['/attachments'],
        command: (event) => { this.closeMenu(); },
        visible: this.loginService.hasPermission("ATTACHMENTS_MENU")
      },
      {
        title: this.translateService.instant("menu.documents").toUpperCase(),
        label: this.getName("genericAttachments", 'Documentos genéricos', 'genericAttachments'),
        icon: 'pi pi-fw pi-folder',
        routerLink: ['/genericAttachments'],
        command: (event) => { this.closeMenu(); },
        visible: this.loginService.hasPermission("GENERIC_ATTACHMENTS_MENU")
      },
      {
        title: this.translateService.instant("menu.documents").toUpperCase(),
        label: this.translateService.instant("component.mailMarketing.entity"),
        icon: 'pi pi-fw pi-envelope',
        routerLink: ['/mailMarketing'],
        command: (event) => { this.closeMenu(); },
        visible: this.loginService.hasPermission("MAIL_MARKETING_MENU")
      },
      {
        title: this.translateService.instant("menu.support").toUpperCase(),
        label: this.translateService.instant("general.configuration"),
        icon: 'pi pi-fw pi-cog',
        routerLink: ['/configuration/entities'],
        command: (event) => { this.closeMenu(); },
        visible: this.loginService.hasPermission("CONFIGURATION")
      }
    ];

    return this.items;
  }

  route(organizationType_id) {
    this.router.navigate(['/organizations', organizationType_id]);
  }

  getName(table, otherName, code, plural = 1) {
    let res = this.entities.filter(m => m.name_table == table)[0];
    if (code != null && code != "") {
      res = this.entities.filter(m => m.name_table == table && m.code == code)[0];
    }

    if (res != null) {
      //buscamos el nombre en el idioma
      var name = res.name_plural;
      var strPlural = "name_plural";
      if (plural == 0) strPlural = "name";
      var key = res.code + ".entity." + strPlural;
      if (this.translateService.instant(key) !== key) {
        name = this.translateService.instant(key);
      }
      return name;
    } else {
      var name = otherName;
      var strPlural = "name_plural";
      if (plural == 0) strPlural = "name";
      var key = code + ".entity." + strPlural;
      if (this.translateService.instant(key) !== key) {
        name = this.translateService.instant(key);
      }
      return name;
    }
  }

  getVersion() {
    this.versionService.get().subscribe(
      data => {
        this.version = data;
      }
    );
  }

}
