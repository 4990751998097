<div class="p-grid p-col p-jc-end view-top-actions" *ngIf="personalize_interface">
  <p-overlayPanel #op appendTo="body">
    <ng-template pTemplate>
      <div style="min-width: 300px;" *ngIf="personalize_interface">
        <label><small>{{'view.configure.which_view_to_show' | translate}}</small></label><br />
        <p-dropdown [options]="dropdownViews" [(ngModel)]="selectedView" optionLabel="name" optionValue="id"
                    [filter]="true" filterBy="name" class="p-field" *ngIf="personalized_views_read" appendTo="body"
                    placeholder="{{'view.configure.select_view' | translate}}" (onChange)="changeCurrentView()">
          <ng-template pTemplate="selectedItem">
            <div class="view-item">
              <div><i class="fa fa-thumbtack p-mr-1"></i> {{view.name}} </div>
            </div>
          </ng-template>
          <ng-template let-view pTemplate="item">
            <!--
            <div *ngIf="view.id != -1"><i class="fa fa-thumbtack p-mr-1" *ngIf="view.id == selectedView"></i>{{view.name}} <span style="font-size: 11px; color: #7b918d" *ngIf="view.metadata?.default == true"> (por defecto)</span> </div>
            <div *ngIf="view.id == -1 && personalized_views_write" class="p-text-center"><p-button label="Añadir nueva vista" icon="pi pi-plus" styleClass="p-ml-2 p-block" (click)="newView(false); event.preventDefault()"></p-button></div>
            -->
            <div style="display:flex;align-items:center;">
              <div style="margin-right: 5px;">
                <i class="fa fa-thumbtack p-mr-1" *ngIf="view.id == selectedView"></i>
              </div>
              <div>
                <div *ngIf="view.id != -1">
                  {{view.name}} <span style="font-size: 11px; color: #7b918d" *ngIf="view.metadata?.default == true">
  ({{'view.configure.default' | translate}})
</span>
                </div>
                <div *ngIf="view.id == -1 && personalized_views_write" class="p-text-center">
                  <p-button label="{{'general.create_new_view' | translate}}" icon="pi pi-plus" styleClass="p-ml-2 p-block"
                            (click)="newView(false); event.preventDefault()"></p-button>
                </div>
                <div *ngIf="view.id > 0 " style="color: #a1b6b2">
                  <small>
                    <label *ngIf="view.user?.name">{{'view.configure.created_by' | translate}} {{view.user.name}}</label>
                    <label *ngIf="!view.user?.name">{{'view.configure.system_view' | translate}}</label>
                    <div *ngIf="view.updatedBy?.name">
                      {{view.updatedBy?.name ? 'Últ. Modificación: '+view.updatedBy.name:""}}
                      {{view.updated_at | date: '(dd/MM/yyyy HH:mm)':'GMT'}} {{view.updated_at ? '' : ''}}
                    </div>
                  </small>
                </div>
              </div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <br />
      <div *ngIf="(view.id && view.user_id == created_by && personalize_interface) || (view.user_id && views_superadmin) || (views_superadmin && isTemplate)">
        <label><small>{{'view.configure.configure_existed' | translate}}</small></label>
        <br />
        <a class="p-button p-mt-2" *ngIf="personalize_interface" (click)="editView(false)">{{'view.configure.modify_view' | translate}}</a>
      </div>
      <div *ngIf="!((view.id && view.user_id == created_by && personalize_interface) || (view.user_id && views_superadmin) || (views_superadmin && isTemplate))">
        <label><small><i class="pi pi-exclamation-triangle"></i>{{'view.configure.cant_modify_view' | translate}}.</small></label>
      </div>
      <br />
      <label *ngIf="personalize_interface || views_superadmin"><small>{{'view.configure.create_new_view' | translate}}</small></label>
      <br />
      <a class="p-button p-mt-2" *ngIf="personalize_interface" (click)="newView()">{{'general.filter.create_new_view' | translate}}</a>
    </ng-template>
  </p-overlayPanel>
  <a class="no-mobile" (click)="opToggle(op, $event)"
     *ngIf="!showConfigOptions && personalized_views_write && personalize_interface && allowViewPersonalize" pTooltip="{{'general.action.configure' | translate}}"
     tooltipPosition="top">
    <fa-icon [icon]="['fas','cog']" style="color: #6e6b7b"></fa-icon>
  </a>
</div>



<div class="toolbar-panel" *ngIf="componentFilter">
  <div class="p-field p-mb-0 p-mr-2 quick-filters" *ngFor="let quick of fieldQuicks">
    <span class="p-input-icon" *ngIf="quick.control_type=='input-text'">
      <i class="pi pi-search"></i>
      <input #inputDuration type="text" pInputText [(ngModel)]="model[quick.model_property]" name="{{quick.model_property}}" placeholder="{{quick.description}}" (keyup.enter)="reloadData(quick.model_property)" />
      <i class="pi pi-times rightIcon" (click)="clearfilter(quick.model_property)" *ngIf="inputDuration.value"></i>
    </span>
  </div>

  <a class="no-mobile p-button p-button-secondary filters" [class.p-button-badge-exclamation]="hasFilterValue" (click)="toggleFilter()" *ngIf="has_filter_panel">
    <i class="fas fa-filter" severity="warning"></i>&nbsp;{{'general.action.filters' | translate}}
  </a>
  <a class="mobile p-button p-button-secondary filters" (click)="toggleFilterMobile()" *ngIf="has_filter_panel">
    <i class="fas fa-filter" severity="warning"></i>
    &nbsp;{{'general.action.filters' | translate}}
  </a>
  <a class="p-ml-2 p-button p-button-secondary" (click)="exportExcel(componentFilter)" *ngIf="componentFilter.allow_export_xls && this.loginService.hasPermission('EXPORT_EXCEL')">
    <fa-icon [icon]="['fas', 'file-excel']"></fa-icon>&nbsp;{{'general.action.export' | translate}}
  </a>

  <!--componentFilter-->
  <div class="actions p-ml-2" *ngIf="has_filter_panel">
    <ng-container *ngFor="let action of componentFilter.headerActions">
      <a *ngIf="isActionVisible(action, item) && !locked" (click)="onActionClick(action, item, $event)" class="p-button {{action.class}}">
        <fa-icon *ngIf="action.icon!=null" [icon]="action.icon"></fa-icon>
        <span>&nbsp;{{getActionLabel(action, item)}}</span>
        <br *ngIf="debugService.enabled" /><small *ngIf="debugService.enabled">{{view.code}}.{{action.name}}</small>
      </a>
    </ng-container>
  </div>
</div>
<div class="" style="display: flex; width: auto;">
  <div class="view">
    <div class="p-grid" *ngIf="showConfigOptions">
      <div class="p-col-12">
        <p-card styleClass="p-mb-3 showConfigOptions">
          <ng-template pTemplate="header">
            <div class="p-card-title" style="display:flex; align-items: center">
              <i class="fas fa-cog fa-2x"></i>
              <div style="margin-left: 10px;">
                {{'view.configure.title_configuration_view' | translate
  }}
  <small>
    {{'view.configure.subtitle_configuration_view' | translate }}
  </small>
</div>
            </div>
          </ng-template>
          <div class="p-grid">
            <div class="p-col-2 p-field">
              <label for="name">
                {{'view.configure.name' | translate }}
              </label>
              <input name="name" type="text" pInputText [required]="true" [(ngModel)]="view.name" />
            </div>
            <div class="p-col-2 p-field" *ngIf="view.id > 1000000">
              <label for="to">{{'view.configure.who_can_use' | translate }}</label>
              <p-dropdown name="to" [required]="true" [options]="optionsMetadataTo" optionLabel="label"
                          optionValue="value" appendTo="body" [(ngModel)]="view.metadata.to"></p-dropdown>
            </div>
            <div class="p-col-4 p-field" *ngIf="view.id > 1000000">
              <label for="default" *ngIf="view.metadata?.to!='onlyme'">{{'view.configure.convert_default' | translate }}</label>
              <p-checkbox id="default" name="default" *ngIf="view.metadata?.to!='onlyme'"
                          [(ngModel)]="view.metadata.default" [binary]="true"></p-checkbox>
            </div>
            <div class="p-field p-col-2 p-mb-0 p-pb-0">
              <label>{{'view.configure.layout' | translate }}</label>
              <p-dropdown name="view.configuration.layout" [(ngModel)]="view.configuration.layout"
                          [options]="allowedLayouts" optionValue="code" optionLabel="name" placeholder="{{'general.select' | translate}}" appendTo="body"
                          (onChange)="onLayoutChange($event)">
              </p-dropdown>
            </div>

          </div>
          <div class="p-field floatRight">

            <a *ngIf="showConfigOptions && view.id && view.id > 1000000" (click)="delete()"
               class="p-button-danger p-button-link p-mr-2 p-button p-component p-ripple" pTooltip="{{'view.configure.delete_view' | translate }}"
               tooltipPosition="top">
              <!--<fa-icon [icon]="['far','trash-alt']"></fa-icon>-->{{'view.configure.delete_view' | translate }}
            </a>
            <a *ngIf="showConfigOptions && view.configuration?.allowedComponents" (click)="addComponent()"
               class="p-button" pTooltip="{{'view.configure.add_component' | translate}}" tooltipPosition="top">
              {{'view.configure.add_component' | translate }}
              <!--<fa-icon [icon]="['fas','plus-circle']"></fa-icon>-->
            </a>
            <a *ngIf="showConfigOptions" (click)="cancel()" pTooltip="{{'general.action.cancel' | translate }}" tooltipPosition="top"
               class="p-button-link p-button-secondary p-ml-2 p-button ">
              <!--<fa-icon [icon]="['far','times-circle']"></fa-icon>-->{{'general.action.cancel' | translate }}
            </a>
            <a *ngIf="showConfigOptions" (click)="save()" pTooltip="{{'general.action.save' | translate }}" tooltipPosition="top" class="p-button">
              <!--<fa-icon [icon]="['fas','save']"></fa-icon>-->{{'general.action.save' | translate }}
            </a>
          </div>
        </p-card>
      </div>
    </div>


    <div class="p-grid {{showConfigOptions?'configuration-mode':''}}" cdkDropListGroup>
      <div *ngFor="let col of layout" class="{{col.class}} layout-col" cdkDropList [cdkDropListData]="col.components"
           (cdkDropListDropped)="drop($event, col)">
        <div class="p-grid">
          <div *ngFor="let component of col.components ; let i = index"
               class="view-component {{showConfigOptions?'view-component-configurable':''}} {{component.class}}" cdkDrag
               [ngStyle]="{'display': this.componentService.isVisible(component,model) ? 'inline' : 'none' }"
               [cdkDragDisabled]="!this.showConfigOptions">
            <div *ngIf="showConfigOptions || componentService.isVisible(component, model)">
              <app-view-component-form *ngIf="component.template=='form'" #form [form]="viewForm" [component]="component"  [view]="view"
                                       [index]="i" [model]="model" [config]="config" [showConfigOptions]="showConfigOptions"
                                       (onEvent)="onComponentEvent($event)">
                <a toolbarStart *ngIf="view.configuration?.allowedComponents">
                  <fa-icon [icon]="['fas', 'trash-alt']" class="color-danger"
                           (click)="removeComponent(col, component, i)"></fa-icon>
                </a>
                <!-- aqui no tiene sentido, solo arriba
                <a toolbarStart (click)="showComponentConfigOP($event, component)" class="internal-action">
                  <fa-icon [icon]="['fas', 'cogs']"></fa-icon>
                </a>
                -->
                <a toolbarStart (click)="opToggleTitle(op2, $event, component)" pTooltip="{{'general.action.options' | translate}}" tooltipPosition="top"
                   class="internal-action">
                  <fa-icon [icon]="['fas','cog']" style="color: #6e6b7b"></fa-icon>
                </a>

                <a toolbarEnd cdkDragHandle>
                  <fa-icon [icon]="['fas', 'arrows-alt']"></fa-icon>
                </a>

              </app-view-component-form>

              <app-view-component-table *ngIf="component.template=='table'" #table [view]="view" [component]="component"
                                        [index]="i" [model]="model" [config]="config" [showConfigOptions]="showConfigOptions"
                                        (onEvent)="onComponentEvent($event)">
                <a toolbarStart *ngIf="view.configuration?.allowedComponents">
                  <fa-icon [icon]="['fas', 'trash-alt']" class="color-danger"
                           (click)="removeComponent(col, component, i)"></fa-icon>
                </a>
                <!-- aqui no tiene sentido, solo arriba
                  <a toolbarStart (click)="showComponentConfigOP($event, component)" class="internal-action">
                    <fa-icon [icon]="['fas', 'cogs']"></fa-icon>
                  </a>
                -->
                <a toolbarStart (click)="opToggleTitle(op2, $event, component)" class="internal-action"
                   pTooltip="{{'view.configure.change_name' | translate}}" tooltipPosition="top">
                  <fa-icon [icon]="['fas','cog']" style="color: #6e6b7b"></fa-icon>
                </a>
                <a toolbarEnd cdkDragHandle>
                  <fa-icon [icon]="['fas', 'arrows-alt']"></fa-icon>
                </a>

              </app-view-component-table>

              <app-view-component-card *ngIf="component.template=='card'" #card [component]="component" [index]="i" [view]="view"
                                       [model]="model" [showConfigOptions]="showConfigOptions" (onEvent)="onComponentEvent($event)">
                <a toolbarStart *ngIf="view.configuration?.allowedComponents">
                  <fa-icon [icon]="['fas', 'trash-alt']" class="color-danger"
                           (click)="removeComponent(col, component, i)"></fa-icon>
                </a>
                <!-- aqui no tiene sentido, solo arriba
                <a toolbarStart (click)="showComponentConfigOP($event, component)" class="internal-action">
                  <fa-icon [icon]="['fas', 'cogs']"></fa-icon>
                </a>
                -->
                <a toolbarStart (click)="opToggleTitle(op2, $event, component)" class="internal-action"
                   pTooltip="{{'view.configure.change_name' | translate}}" tooltipPosition="top">
                  <fa-icon [icon]="['fas','cog']" style="color: #6e6b7b"></fa-icon>
                </a>
                <a toolbarEnd cdkDragHandle>
                  <fa-icon [icon]="['fas', 'arrows-alt']"></fa-icon>
                </a>

              </app-view-component-card>

              <app-view-component-gallery *ngIf="component.template=='gallery'" #gallery [component]="component" [view]="view"
                                          [index]="i" [model]="model" [showConfigOptions]="showConfigOptions" (onEvent)="onComponentEvent($event)">
                <a toolbarStart *ngIf="view.configuration?.allowedComponents">
                  <fa-icon [icon]="['fas', 'trash-alt']" class="color-danger"
                           (click)="removeComponent(col, component, i)"></fa-icon>
                </a>
                <!-- aqui no tiene sentido, solo arriba
                  <a toolbarStart (click)="showComponentConfigOP($event, component)" class="internal-action">
                    <fa-icon [icon]="['fas', 'cogs']"></fa-icon>
                  </a>
                  -->
                <a toolbarEnd cdkDragHandle>
                  <fa-icon [icon]="['fas', 'arrows-alt']"></fa-icon>
                </a>
              </app-view-component-gallery>

              <app-view-component-files *ngIf="component.template=='files'" #file [component]="component" [index]="i" [view]="view"
                                        [model]="model" [showConfigOptions]="showConfigOptions" (onEvent)="onComponentEvent($event)">
                <a toolbarStart *ngIf="view.configuration?.allowedComponents">
                  <fa-icon [icon]="['fas', 'trash-alt']" class="color-danger"
                           (click)="removeComponent(col, component, i)"></fa-icon>
                </a>
                <!-- aqui no tiene sentido, solo arriba
                  <a toolbarStart (click)="showComponentConfigOP($event, component)" class="internal-action">
                    <fa-icon [icon]="['fas', 'cogs']"></fa-icon>
                  </a>
                  -->
                <a toolbarEnd cdkDragHandle>
                  <fa-icon [icon]="['fas', 'arrows-alt']"></fa-icon>
                </a>
              </app-view-component-files>

              <app-view-component-custom *ngIf="component.template=='custom'" #custom [view]="selfView" [component]="component" [index]="i"
                                         [model]="model" [showConfigOptions]="showConfigOptions" (onEvent)="onComponentEvent($event)" [entity]="entity">
                <a toolbarStart *ngIf="view.configuration?.allowedComponents">
                  <fa-icon [icon]="['fas', 'trash-alt']" class="color-danger"
                           (click)="removeComponent(col, component, i)"></fa-icon>
                </a>
                <a toolbarStart (click)="opToggleTitle(op2, $event, component)" pTooltip="{{'view.configure.change_name' | translate }}"
                   tooltipPosition="top">
                  <fa-icon [icon]="['fas','cog']" style="color: #6e6b7b"></fa-icon>
                </a>
                <a toolbarEnd cdkDragHandle>
                  <fa-icon [icon]="['fas', 'arrows-alt']"></fa-icon>
                </a>
              </app-view-component-custom>

            </div>
          </div>
          <div *ngIf="showConfigOptions && col.components.length==0" class="placeholder">
            {{'view.configure.drag_component' | translate }}
          </div>
        </div>
      </div>
    </div>

  </div>

  <!--<div *ngIf="show_filter_panel" class="no-mobile p-mt-4" style="width: 350px; background-color: white; overflow-y: auto; flex-grow: 1">
    <app-view-component-filter [component]="componentFilter" [model]="model" (onEvent)="onComponentEvent($event)" [view]="view"></app-view-component-filter>
  </div>-->
</div>

<div *ngIf="show_filter_panel || show_filter_panel_mobile">
  <p-sidebar [(visible)]="show_filter_panel" position="right" styleClass="p-sidebar-content-no-padding p-sidebar-content-no-footer">
    <ng-template pTemplate="header">{{'general.filter.title' | translate}}</ng-template>
    <app-view-component-filter [component]="componentFilter" [model]="model" (onEvent)="onComponentEvent($event)" [view]="view"></app-view-component-filter>
  </p-sidebar>
</div>

<!--<div *ngIf="show_filter_panel_mobile">
  <p-sidebar class="mobile" [(visible)]="show_filter_panel_mobile" position="right">
    <app-view-component-filter [component]="componentFilter" [model]="model" (onEvent)="onComponentEvent($event)" [view]="view"></app-view-component-filter>
  </p-sidebar>
</div>-->

<p-overlayPanel appendTo="body" #op2>
  <ng-template pTemplate>
    <div class="p-field">
      <label>{{'view.configure.title' | translate}}</label>
      <div class="p-inputgroup">
        <input pInputText [(ngModel)]="component.title" />
      </div>
    </div>
    <div class="p-field" *ngIf="component.template=='table'">
      <p-checkbox [(ngModel)]="component.allow_export_xls" [binary]="true" label="{{'view.configure.export_excel' | translate}}"></p-checkbox>
    </div>
    <div class="p-field" *ngIf="component.template=='table'">
      <p-checkbox [(ngModel)]="component.show_header" [binary]="true" label="{{'view.configure.show_header' | translate}}"></p-checkbox>
    </div>
    <div class="p-field" *ngIf="component.template=='form'">
      <p-checkbox *ngIf="showTrackingOption" [(ngModel)]="component.show_tracking" [binary]="true" label="{{'view.configure.show_tracking' | translate}}"></p-checkbox>
    </div>
    <div class="p-field p-d-flex p-jc-center" *ngIf="component.template=='table'">
      <button pButton type="button" (click)="tableColumnsAdjust()">{{'view.configure.automatic_adjust' | translate}}</button>
    </div>
  </ng-template>
</p-overlayPanel>
