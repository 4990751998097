<ul class="breadcrumb" *ngIf="dialogRef == null">
  <li><a [routerLink]="['/home']"><span class="color-soft-grey-axial">{{'general.breadcrumb.you_are' | translate}} </span>{{'general.breadcrumb.home' | translate}}</a></li>
  <li *ngIf="task.organization_id != null && task.organization_id != 0"><i class="pi pi-angle-right"></i></li>
  <li *ngIf="task.organization_id != null && task.organization_id != 0"><a [routerLink]="['/organizations']">{{'organization.entity.name_plural' | translate}}</a></li>
  <li *ngIf="task.organization_id != null && task.organization_id != 0"><i class="pi pi-angle-right"></i></li>
  <li *ngIf="task.organization_id != null && task.organization_id != 0"><a
      [routerLink]="['/organizations', 0, task.organization_id]">{{task.organization?.name}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li><a [routerLink]="['/tasks']">{{'task.entity.name_plural' | translate}}</a></li>
  <li><i class="pi pi-angle-right"></i></li>
  <li [hidden]="task.id==null"><a [routerLink]="['/tasks',task.organization_id, task.id]">{{task.subject}}</a></li>
</ul>
<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-mb-0 p-mb-lg-4">

  <div class="">
    <ul class="step-line">
      <li (click)="status_selected(status.id)" *ngFor="let status of tasks_status"
        [ngClass]="task.task_status_id == status.id?'bg-'+status.css_class:''">
        {{status.name}}<span class="separator"></span>
      </li>
    </ul>
  </div>
  <div style="display: flex; gap: 10px; align-items: center;">
    <p-button *ngIf="showPrevButton" class="p-button-info p-button-outlined" (click)="loadPrevNext(1)"
      style="font-size: 10px;">
      <div class="button-prev">
        <i class="pi pi-angle-left p-mr-2"></i>
        <div style="font-size: 10px;">
          <div><b>{{task_prev?.organization?.name}}</b></div>
          <div>{{task_prev?.taskActionType?.name}}</div>
          <!--div *ngIf="task_prev.subject != null && task_prev.subject != ''">
            {{ task_prev.subject.length > 20 ? task_prev.subject.slice(0, 20) + '...' : task_prev.subject }}
          </div-->
        </div>
      </div>
    </p-button>

    <p-button *ngIf="showNextButton" class="p-button-info p-button-outlined" (click)="loadPrevNext(0)"
      style="font-size: 10px;">
      <div class="button-next">
        <div style="font-size: 10px;">
          <div><b>{{task_next?.organization?.name}}</b></div>
          <div>{{task_next?.taskActionType?.name}}</div>
          <!--div *ngIf="task_next.subject != null && task_next.subject != ''">
            {{ task_next.subject.length > 20 ? task_next.subject.slice(0, 20) + '...' : task_next.subject }}
          </div-->
        </div>
        <i class="pi pi-angle-right p-ml-2"></i>
      </div>
    </p-button>

  </div>
</div>

<app-view #view [code]="'tasks.edit'" [model]="task" [config]="viewConfig" (onEvent)="onEvent($event)"></app-view>
<div [ngStyle]="{'top':yPos,'left':xPos}" class="tooltip-div" *ngIf="tooltip_info.show && false">
  <span class="tooltiptext">
    <b>{{tooltip_info.mode}}</b><br />
    <small>
      {{'task.entity_fields.subject' | translate}}: {{tooltip_info.subject}} <br />
      {{'task.entity_fields.organization_id' | translate}}: {{tooltip_info.organization}} <br />
      {{'task.entity_fields.responsible_user_id' | translate}}: {{tooltip_info.responsible}} <br />
      {{'task.entity_fields.contact_id' | translate}}: {{tooltip_info.contact}} <br />
      {{'component.tasks.detail.last_action_date' | translate}}: {{tooltip_info.last_action_date | date: 'dd/MM/yyyy hh:mm'}} <br />
    </small>
  </span>
</div>
